import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    {...props}
  >
    <path d="m4.25 9.75-2-.5s0-1.5.5-3 4-1.5 4-1.5m-.5 7 .5 2s1.5 0 3-.5 1.5-4 1.5-4m-7 .5 2 2s5-2 6.5-4.5 1.5-5.5 1.5-5.5-3 0-5.5 1.5-4.5 6.5-4.5 6.5" />
    <path d="m1.75 14.25 2-1-1-1z" fill="#000" />
    <circle cx={10.25} cy={5.75} r={0.5} fill="#000" />
  </svg>
);
export default SVGComponent;

// import { BSC } from '@usedapp/core'
// import { Base } from '@usedapp/core'
// import { BscTestnet } from './chain'
import { Base } from './testchain'

export const BACKEND_URL = "https://mettfiserver.cloud"
// export const BACKEND_URL = "http://203.175.11.225"
// export const BACKEND_URL = "http://127.0.0.1:5002"

/* export const FairLaunch_FACTORY = {
  [BSC.chainId]: "0x827fF36ff1E7bfc2D854456bE32f6B6445e84A1F",
  [ZetaChain.chainId]: "0x72762F4a3d46EC79B09363848caC70FD86c7D740",
  [ShardeumChain.chainId]: "0xf8a14CaEcDc5627c556FB32620c37D4635946289",
  [Base.chainId]: "0x73C98555cEd0d331D7B94f7B1eCd2cbF617D068d",
}

export const FairLaunchErc_FACTORY = {
  [BSC.chainId]: "",
  [ZetaChain.chainId]: "",
  [ShardeumChain.chainId]: "0x3D6281e5cA11a7746184918bdDFAAA9d351874c1",
  [Base.chainId]: "",
}

export const Private_FACTORY = {
  [BSC.chainId]: "",
  // [ZetaChain.chainId]: "0x7B2572734F1DD8F67c7E1Bbc8b56886908dB57a7",
  [ZetaChain.chainId]: "0x1fd6a4b4b6a8d0f4c380a8645041073583E7b30d",
  [ShardeumChain.chainId]: "0xC75BD0d0b9B12dAefB0354C478620484319d451B",
  [Base.chainId]: "",
}

export const PrivateErc_FACTORYADRESS = {
  [BSC.chainId]: "",
  [ZetaChain.chainId]: "",
  [ShardeumChain.chainId]: "0x80EC4e2A0983c84F02c0335D24290FEa8dc61a9E",
  [Base.chainId]: "",
}

export const PublicVesting = {
  [BSC.chainId]: "",
  // [ZetaChain.chainId]: "0x290b0FE6b891C44D18dBFCD9c101735D3ceE82dC",
  [ZetaChain.chainId]: "0xcd0Cf46c6cCb00713494c9C6F41aa852f40784bd",
  [ShardeumChain.chainId]: "0x5709985bEb87f96873F908d161DcB949183798E6",
  [Base.chainId]: "0xc4fFE53C82028C2Bb775AC3EEC48a10D79143f9C",
}

export const Public_FACTORY = {
  [BSC.chainId]: "0xa9D1bB1eb022c377C95406aF4538CE7a37dF6b24",
  // [ZetaChain.chainId]: "0xcf7a52DA6ae7D1663500D505fB95f19E4BE5c487", // Testnet
  [ZetaChain.chainId]: "0xEfC53B1F57345857696655Da289D16B52AD3d273",//Mainnet
  [ShardeumChain.chainId]: "0xaF5d48424125D1801ad12e052cb5223703658cbB", // Testnet
  [Base.chainId]: "0x0Ccef961139384Af6bEdA65e15Ecd93b695EDEc2",
}

export const PublicErc_FACTORYADRESS = {
  [BSC.chainId]: "0x686F16B4809b6A470479D66bB9A67F3284954fF3",
  [ZetaChain.chainId]: "0x869f94b07F9B1f0923bbA6e25CEad91F7Ce84e24",
  [ShardeumChain.chainId]: "0x7bdF65A7d9a8b6d01F43094b08AF04faf1138739",
  [Base.chainId]: "",
}

export const LOCK_ADDRESS = {
  [BSC.chainId]: "0x47D166313616c23616c8Dd88c3339A350471D2bb",
  [ZetaChain.chainId]: "0xA295f2fb7616aE05508C1eB7643eB9F08D02b3d2",
  [ShardeumChain.chainId]: "0xEd5c22739B3D6975E597A34Ca8B5540127473bC9",
  [Base.chainId]: "",
}

export const ROUTER_ADDRESS = {
  [BSC.chainId]: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
  [ZetaChain.chainId]: "0xeeF9560CE1f1358011E41974dbE68F8a0Bd86E50",
  [ShardeumChain.chainId]: "0x37b1fC2b5062526bf6b177Fdcd5992122b138c4d",
  [Base.chainId]: "0xfA701a1dc06066029FF58EC6d6A5f944ba0e467a",
}

export const ADMIN_ADDRESS = {
  [BSC.chainId]: "0xcc361f580590D40edfE809C7Aba736e0B798a63E",
  [ZetaChain.chainId]: "0x34C631C5AfB39B448b2e50378F6D2C42500175F6",
  [ShardeumChain.chainId]: "0x401ACd2891f3408F5B6bB6e25bBF0cb55946CB4C",
  [Base.chainId]: "0xcc361f580590D40edfE809C7Aba736e0B798a63E",
}

export const USDT_ADDRESS = {
  [BSC.chainId]: "",
  [ZetaChain.chainId]: "",
  [ShardeumChain.chainId]: "0x96a0fa4da6270cf2b65dd21e4fc2e387edeb8951",
  [Base.chainId]: "",
}

export const TOWER_TOKEN = {
  [BSC.chainId]: "0x53a60f0fB1D1bff7fF9e75C9A26e3504ff0C1FBb",
  [ZetaChain.chainId]: "0x0915bC79D6e9Ab5808987b383242d3AE2f923b32",
  [ShardeumChain.chainId]: "0x8D6E7213bad28E00156c7ecddEFac64Cc508CAD5",
  [Base.chainId]: "",
}

export const USDC_ADDRESS = {
  [BSC.chainId]: "",
  [ZetaChain.chainId]: "",
  [ShardeumChain.chainId]: "0xd9ad6a7d0052e04b1eaa7b340d48b0fcae1aff96",
  [Base.chainId]: "",
}

export const TWSDROP_ADDRESS = {
  [BSC.chainId]: "",
  [ZetaChain.chainId]: "",
  [ShardeumChain.chainId]: "0x5B40F80d474526632D36Cc4fBb1Fb3920145c9ec",
  [Base.chainId]: "",
}
*/

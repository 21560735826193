import { Chain } from '@usedapp/core'

/* export const ShardeumChain: Chain = {
  chainId: 8082,
  chainName: 'Shardeum',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xc3608607149c88785908a8345f83f9bf8c450fea',
  getExplorerAddressLink: (address) => `https://explorer-sphinx.shardeum.org/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://explorer-sphinx.shardeum.org/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://sphinx.shardeum.org',
  blockExplorerUrl: 'https://explorer-sphinx.shardeum.org/',
  nativeCurrency: {
    name: 'SHARDEUM',
    symbol: 'SHM',
    decimals: 18,
  },
}

export const ZetaChain: Chain = {
  chainId: 7000,
  chainName: 'ZetaChain',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xD89f6F603117F81cEd171D223cf2b7E2513CDf20',
  getExplorerAddressLink: (address) => `https://zetachain.blockscout.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://zetachain.blockscout.com/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://zetachain-mainnet.public.blastapi.io',
  blockExplorerUrl: 'https://zetachain.blockscout.com/',
  nativeCurrency: {
    name: 'ZetaChain',
    symbol: 'ZETA',
    decimals: 18,
  },
} */

export const Base: Chain = {
  chainId: 8453,
  chainName: 'Base Mainnet',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x7eB36E98d2fC1768468EB9A44444bFC1541fEae2',
  getExplorerAddressLink: (address) => `https://https://basescan.org/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://basescan.org/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://mainnet.base.org',
  blockExplorerUrl: 'https://basescan.org',
  nativeCurrency: {
    name: 'BASE ETH',
    symbol: 'ETH',
    decimals: 18,
  },
}

/* export const BSC: Chain = {
  chainId: 56,
  chainName: 'BSC',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  getExplorerAddressLink: (address) => `https://bscscan.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://bscscan.com/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://bsc-dataseed1.ninicoin.io/',
  blockExplorerUrl: 'https://bscscan.com/',
  nativeCurrency: {
    name: 'Binance Smart Chain',
    symbol: 'BNB',
    decimals: 18,
  },
} */

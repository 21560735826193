import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import BaseLayout from '../BaseLayout/BaseLayout';
import GoBackIcon from '../../svgs/New/Back2';
import Sidebar from "../Content/Sidebar";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";

const AnnouncementDetails = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/news/all-news`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const foundAnnouncement = data.find(a => a.id === parseInt(id));

        if (foundAnnouncement) {
          // Mengganti URL gambar dengan tag <img>
          const contentWithImages = foundAnnouncement.content
            .replace(
              /(https?:\/\/[^\s]+(\.png|\.jpg|\.jpeg|\.gif))/gi,
              '<div style="display: flex; justify-content: center; align-items: center;"><img src="$1" alt="news image" style="max-width: 100%; border-radius: 20px; height: 200px;" /></div>'
            )
            .replace(
              /s+https?:\/\/[^\s]+/gi, // Menangani link yang bukan gambar
              '<a href="$1" style="color: blue; text-decoration: underline;" rel="noopener noreferrer">$1</a>'
            )
            .replace(
              /<p style="text-align: (left|center|right);">/gi,
              '<div style="text-align: $1;">'
            )
            .replace(
              /<\/p>/gi,
              '</div>'
            )
            .replace(
              /<ul>/gi,
              '<ul class="announcement-list">'
            )


          // Menghilangkan teks tambahan
          setAnnouncement({
            ...foundAnnouncement,
            content: contentWithImages,
          });
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncement();
  }, [id]);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <BaseLayout>
      <div className=" flex flex-col lg:flex-row min-h-screen bg-[#f1f5f9] mb-10">
        <div className="w-full lg:w-4/6 p-4 border-2 border-gray rounded-xl mt-4 md:ml-2 bg-white rounded-xl">
          <div className="bg-[white] relative md:p-4">
            <Link
              to="/announcements"
              className="flex left-4 font-lato items-center text-black px-2 pb-2"
            >
              <GoBackIcon className="mr-1" /> Back
            </Link>

            {announcement ? (
              <>
                <img
                  src={announcement.banner}
                  alt={announcement.title}
                  className="w-full object-cover rounded-md mb-2"
                />
                <p className="text-gray-500 font-lato mb-4">
                  Meet Finance | <span className="text-xs font-lato">{formatDate(announcement.createdAt)}</span>
                </p>
                <h2 className="text-2xl font-lato font-bold mb-4">{announcement.title}</h2>
                <div
                  className="text-gray-700 text-justify font-lato"
                  dangerouslySetInnerHTML={{ __html: announcement.content }}
                />
              </>
            ) : (
              <p className="text-red-500">News not found</p>
            )}
          </div>
        </div>
        <div className="hidden lg:block lg:w-2/6 p-4">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default AnnouncementDetails;

import React, { useState } from "react";
import { useEthers } from '@usedapp/core';
import PostFeed from "./PostFeed";
import Sidebar from "./Sidebar";
import BaseLayout from "../BaseLayout/BaseLayout";
import CreateContent from "./Create/CreateContent";
import ExploreIcon from '../../svgs/Sidebar/dashboard';
import WarningIcon from '../../svgs/New/Warning';

const HomePage = () => {
  const { account } = useEthers();
  const [activeTab, setActiveTab] = useState("Recents");

  // Handle tab click
  const handleTabClick = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  return (
    <BaseLayout>
      <div className="flex md:py-4 flex-col lg:flex-row bg-[#f1f5f9] mb-2">
        {/* Main Content */}
        <div className="xl:w-3/5 px-2 border-2 border-gray rounded-xl md:mt-2 md:ml-2 bg-white rounded-2xl">
          {account ? (  // Cek apakah akun terhubung
            <CreateContent />
          ) : (
            <p className="flex items-center justify-center font-lato font-bold text-xs md:text-lg text-center text-bold">
            </p>
          )}
          <div className="flex mb-4 flex items-center mt-4 justify-between">
            <h1 className="flex text-xl font-lato items-center font-bold ml-2">
              {/* <ExploreIcon className="w-6 h-6"/> */} Feeds
            </h1>
            <ul className="flex text-xs md:text-sm bg-gray-200 px-1 py-1 rounded-full font-lato">
              <li
                className={`mr-1 px-2 py-1 cursor-pointer ${
                  activeTab === "Recents" ? "border-2 rounded-full border-white bg-[white] text-black" : "text-gray-500"
                }`}
                onClick={() => handleTabClick("Recents")}
              >
                Recents
              </li>
              <li
                className={`mr-1 px-2 py-1 cursor-pointer ${
                  activeTab === "Popular" ? "border-2 rounded-full border-white bg-[white] text-black" : "text-gray-500"
                }`}
                onClick={() => handleTabClick("Popular")}
              >
                Popular
              </li>
              <li
                className={`mr-1 px-2 py-1 cursor-pointer ${
                  activeTab === "Friends" ? "border-2 rounded-full border-white bg-[white] text-black" : "text-gray-500"
                }`}
                onClick={() => handleTabClick("Friends")}
              >
                Friends
              </li>
            </ul>
          </div>

          {/* Tab Content */}
          <div>
            {activeTab === "Popular" && <PostFeed category="Popular" />}
            {activeTab === "Friends" && <PostFeed category="Friends" />}
            {activeTab === "Recents" && <PostFeed category="Recents" />}
          </div>
        </div>

        <div className="hidden lg:block lg:w-2/5 p-2 ">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default HomePage;

import React from 'react';

export default function PassedVotes({ proposals }) {
  const passedProposals = proposals.filter(proposal => proposal.status === 'passed');

  return (
    <section className="mb-8">
      <h2 className="text-xl font-semibold mb-4 font-lato">Passed Votes</h2>
      {passedProposals.length > 0 ? (
        passedProposals.map(proposal => (
          <div key={proposal.id} className="bg-white shadow-md rounded-lg p-4 mb-4 font-lato">
            <h3 className="text-lg font-bold">{proposal.title}</h3>
            <p className="text-gray-700">{proposal.description}</p>
            <p className="text-green-500 font-semibold">Status: {proposal.result}</p>
          </div>
        ))
      ) : (
        <p>No vote available</p>
      )}
    </section>
  );
}

import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useEthers } from '@usedapp/core';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../Content/Sidebar";
import BaseLayout from "../BaseLayout/BaseLayout";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";

const RegisterVerify = () => {
  const { walletAddress } = useParams();
  const { account } = useEthers();
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();


  const handleRegister = async () => {
    // Konversi walletAddress ke huruf kecil
    const normalizedWalletAddress = walletAddress.toLowerCase();

    if (!email || !normalizedWalletAddress) {
      setMessage('Email cant be empty');
      return;
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/api/register`, { email, walletAddress: normalizedWalletAddress });
      setMessage(response.data.message);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Failed to register';
      setMessage(errorMessage);
      console.error('Registration error:', error);
    }
  };

  const handleVerify = async () => {
    const normalizedWalletAddress = walletAddress.toLowerCase();
    try {
      const response = await axios.post(`${BACKEND_URL}/api/verify`, { email, walletAddress: normalizedWalletAddress, verificationCode });
      setIsVerified(true);
      setMessage(response.data.message);
      navigate(`/account/${account}`);
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Verfication Failed');
    }
  };


  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row min-h-screen bg-white mb-10">
        <div className="w-full lg:w-3/5 p-4">
          <div className="max-w-md mx-auto p-4 border border-gray-300 rounded">
            <h2 className="text-xl font-lato font-semibold mb-4">Email verification</h2>
            <div className="mb-4">
              <label className="block mb-2 font-lato">Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 rounded-xl p-2 w-full"
              />
              <button
                onClick={handleRegister}
                className="mt-2 px-4 py-2 font-lato text-sm bg-blue-500 text-white rounded-full"
              >
                Get Code
              </button>
            </div>

            <div className="mb-4">
              <label className="block mb-2 font-lato">Enter Verification Code: </label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="border border-gray-300 rounded-xl p-2 w-full"
                disabled={!email}
              />
              <button
                onClick={handleVerify}
                className="mt-2 px-4 py-2 font-lato text-sm bg-green-500 text-white rounded-full"
                disabled={!email || !verificationCode}
              >
                Verify Code
              </button>
            </div>

            {message && <p className="mt-2 text-red-600">{message}</p>}

            {isVerified && (
              <p className="mt-4 text-green-600">Email verification success!!</p>
            )}
          </div>
        </div>
        <div className="hidden lg:block lg:w-2/5 p-4">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default RegisterVerify;

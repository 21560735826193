import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 72 72"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M15.244 58.063c-2.168 0-3.744-1.781-3.744-3.969V37.1a1 1 0 1 0-2 0v16.994c0 3.291 2.473 5.969 5.744 5.969a1 1 0 1 0 0-2m4.88.037h-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2" />
    <path d="M62.568 34.1h-4.067v-9.143c0-3.78-3.485-6.856-7.308-6.856h-2.901C47.516 10.694 41.234 4.9 33.625 4.9c-7.61 0-13.891 5.794-14.668 13.201H9.056c-3.823 0-6.556 3.076-6.556 6.856v35.137c0 3.863 2.734 7.006 6.556 7.006h42.137c3.822 0 7.307-3.143 7.308-7.006V52.1h4.067a6.933 6.933 0 0 0 6.932-6.932h.001v-4.137a6.933 6.933 0 0 0-6.933-6.931M33.625 8.9c5.4 0 9.87 4.007 10.625 9.201H22.999c.754-5.194 5.224-9.201 10.626-9.201m20.876 51.194c0 1.629-1.718 3.006-3.308 3.006H9.056c-1.589 0-2.556-1.377-2.556-3.006V24.956c0-1.601.912-2.856 2.556-2.856h42.137c1.645 0 3.308 1.254 3.308 2.856V34.1h-6.069a6.93 6.93 0 0 0-6.932 6.931v4.137a6.933 6.933 0 0 0 6.932 6.932h6.069zm11-14.926a2.933 2.933 0 0 1-2.933 2.932H48.432a2.93 2.93 0 0 1-2.932-2.932v-4.137a2.93 2.93 0 0 1 2.932-2.932h14.137a2.93 2.93 0 0 1 2.932 2.932h.001v4.137z" />
    <circle cx={50.542} cy={43.024} r={1.917} />
  </svg>
);
export default SVGComponent;

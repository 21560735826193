import React, { useState } from 'react';
import BaseLayout from '../BaseLayout/BaseLayout';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Sidebar from "../Content/Sidebar";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";

const CreateNews = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(''); // Ini akan berisi konten dari ReactQuill
  const [banner, setBanner] = useState('');
  const [error, setError] = useState('');

  // Nama admin otomatis diisi "MeetFi Admin"
  const admin = 'MeetFi Admin';

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validasi input
    if (!title || !content || !banner) {
      setError('Semua bidang harus diisi');
      return;
    }

    const formattedContent = content
        // Ganti tautan gambar menjadi tag <img>
        .replace(/<a href="([^"]+)"[^>]*>(.*?)<\/a>/g, (match, url) => {
          if (url.match(/\.(jpeg|jpg|gif|png|svg)$/)) {
            return `<img src="${url}" alt="news image" style="max-width: 100%; border-radius: 20px; height: auto;" />`;
          }
          return match; // Kembalikan tautan asli jika bukan gambar
        })
        // Ganti tautan biasa dengan format baru
        .replace(/<a href="([^"]+)"(.*?)>(.*?)<\/a>/g, '<a href="$1" target="_blank" style="color: blue; text-decoration: underline;" $2>$3</a>');

    try {
      const response = await fetch(`${BACKEND_URL}/api/news/new-news`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title, content: formattedContent, banner, admin }),
      });

      if (response.ok) {
        // Reset form
        setTitle('');
        setContent('');
        setBanner('');
        setError('');
        alert('Berita berhasil dibuat!');
      } else {
        throw new Error('Gagal mengirim data');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Custom toolbar untuk menyisipkan gambar
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      [{ 'align': [] }],
      ['link', 'image'], // Opsi gambar ditambahkan di sini
      ['clean'],
    ],
  };

  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row min-h-screen bg-white mb-10">
        <div className="w-full lg:w-3/5 p-4 border border-gray-100 rounded-xl">
          <div className="p-4 max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Buat Berita Baru</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700">Judul</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="mt-1 block w-full border rounded-md p-2"
                  placeholder="Masukkan judul"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Konten</label>
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  className="mt-1 block w-full"
                  modules={modules} // Menggunakan custom toolbar
                  placeholder="Masukkan konten"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Banner URL</label>
                <input
                  type="text"
                  value={banner}
                  onChange={(e) => setBanner(e.target.value)}
                  className="mt-1 block w-full border rounded-md p-2"
                  placeholder="Masukkan URL banner"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Admin</label>
                <input
                  type="text"
                  value={admin}
                  disabled
                  className="mt-1 block w-full border rounded-md p-2 bg-gray-200"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
              >
                Kirim
              </button>
            </form>
          </div>
        </div>
        <div className="hidden lg:block lg:w-2/5 p-4">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default CreateNews;

import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 448 448"
    {...props}
  >
    <text
      y={1009.712}
      x={51.232}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontSize: "13.8125px",
        lineHeight: "125%",
        fontFamily: "Calibri",
        textAlign: "start",
        letterSpacing: 0,
        wordSpacing: 0,
        textAnchor: "start",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
      }}
      xmlSpace="preserve"
      transform="translate(0 -604.362)"
    >
      <tspan y={0} x={0} />
    </text>
    <text
      y={1080.2}
      x={82.93}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontSize: "13.8125px",
        lineHeight: "125%",
        fontFamily: "Calibri",
        textAlign: "start",
        letterSpacing: 0,
        wordSpacing: 0,
        textAnchor: "start",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
      }}
      xmlSpace="preserve"
      transform="translate(0 -604.362)"
    >
      <tspan y={0} x={0} />
    </text>
    <text
      y={1331.036}
      x={414.305}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontSize: "82.5056076px",
        lineHeight: "125%",
        fontFamily: "Calibri",
        textAlign: "start",
        letterSpacing: 0,
        wordSpacing: 0,
        textAnchor: "start",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
      }}
      xmlSpace="preserve"
      transform="matrix(1.00894 0 0 .99114 0 -604.362)"
    >
      <tspan y={0} x={0} />
    </text>
    <g
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontSize: "16.91569519px",
        lineHeight: "125%",
        fontFamily: "&quot",
        textAlign: "start",
        letterSpacing: 0,
        wordSpacing: 0,
        textAnchor: "start",
        fillOpacity: 1,
        stroke: "none",
      }}
    >
      <path
        d="M24.102 1027.362q.719 0 1.388.198.677.199 1.263.562.595.355 1.082.85.496.488.851 1.083.355.586.545 1.247.199.66.199 1.355 0 .743-.207 1.445-.198.694-.562 1.313-.363.612-.867 1.124-.495.504-1.09.867-.587.363-1.256.57-.66.198-1.346.198-.545 0-.958-.05-.413-.058-.752-.157-.33-.107-.603-.248-.264-.14-.52-.314l-4.138 4.155q-.355.29-.776.29-.232 0-.463-.092-.231-.09-.446-.28-.231-.199-.339-.463-.107-.264-.107-.545 0-.272.1-.537.106-.264.296-.454l3.99-3.981q-.438-.702-.603-1.404-.165-.71-.165-1.437 0-.736.214-1.413.215-.685.587-1.272.38-.595.9-1.074t1.124-.817q.61-.347 1.288-.529.677-.19 1.371-.19m-3.791 5.295q0 .8.28 1.495.29.693.794 1.205.512.504 1.206.802.693.289 1.511.289.785 0 1.446-.29.669-.297 1.148-.8.479-.513.743-1.206.273-.694.273-1.495 0-.768-.273-1.43-.273-.66-.76-1.14-.479-.486-1.14-.759t-1.437-.273-1.47.273q-.686.273-1.206.76-.512.479-.818 1.14-.297.652-.297 1.429"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontSize: "16.91569519px",
          fontFamily: "&quot",
        }}
        transform="matrix(31.0474 0 0 30.92449 -465.711 -31770.652)"
      />
    </g>
    <text
      y={1087.359}
      x={85.309}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontSize: "13.8125px",
        lineHeight: "125%",
        fontFamily: "Calibri",
        textAlign: "start",
        letterSpacing: 0,
        wordSpacing: 0,
        textAnchor: "start",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
      }}
      xmlSpace="preserve"
      transform="translate(0 -604.362)"
    >
      <tspan y={0} x={0} />
    </text>
  </svg>
);
export default SVGComponent;

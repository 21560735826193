import React, { useEffect } from 'react';
import Modal, { useModalState } from 'react-simple-modal-provider';
import { Base } from 'config/constants/testchain';
import { wallets } from '../../data/wallets';
import { useEthers } from '@usedapp/core';
import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js';
import { getNetworkConfig } from '../../config/networks';
import { useDefaultChainId } from '../../config/useDefaultChainId';
import { MetaMaskSDK } from '@metamask/sdk';

export default function ConnectionModal({ children }) {
  const { defaultChainId, chainId } = useDefaultChainId();
  const config = getNetworkConfig(defaultChainId);
  const { switchNetwork, account, activate } = useEthers();
  const [isOpen, setOpen] = useModalState();

  const MMSDK = new MetaMaskSDK({
    dappMetadata: {
      name: 'Meet Finance',
      url: window.location.href,
    },
  });
  const ethereum = MMSDK.getProvider();

  const onConnect = async () => {
    if (chainId !== Base.chainId) {
      await switchNetwork(Base.chainId);
    }
    try {
      const provider = new WalletConnectProvider({
        rpc: config.readOnlyUrls,
      });
      await provider.enable();
      await activate(provider);
    } catch (error) {
      console.error(error);
    }
  };

  const onMetamask = async () => {
    try {
      const ethereum = MMSDK.getProvider() || window.ethereum;
      if (!ethereum) {
        throw new Error('MetaMask is not installed.');
      }
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      if (chainId !== Base.chainId) {
        await switchNetwork(Base.chainId);
      }
      await activate(ethereum);
      // window.location.href = 'https://meetfi.org/'; 
    } catch (error) {
      console.error('MetaMask connection error:', error);
    }
  };

  useEffect(() => {
  if (ethereum) {
    ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else {
        activate(ethereum);
      }
    });

    ethereum.on('chainChanged', (chainId) => {
      if (chainId !== Base.chainId) {
        switchNetwork(Base.chainId);
      }
    });
  }
}, [ethereum, activate, switchNetwork]);


  // Cek localStorage untuk koneksi wallet sebelumnya
  useEffect(() => {
    const savedAccount = localStorage.getItem('connectedAccount');
    if (savedAccount) {
      // Jika ada akun yang tersimpan, lakukan koneksi otomatis
      activate(ethereum);
    }
  }, [activate]);

  useEffect(() => {
    if (account) {
      setOpen(false);
    }
  }, [account, setOpen]);

  return (
    <Modal id={'ConnectionModal'} consumer={children} isOpen={isOpen} setOpen={setOpen} allowClickOutside={false}>
      <div className="p-9 w-[90%] max-w-[520px] bg-white dark:bg-dark-1 rounded-[10px] mx-auto">
        <div className="flex justify-between items-center  ">
          <span className="text-dark-text dark:text-light-text font-lato font-semibold text-lg">Connect Wallet</span>

          <div className="flex items-center cursor-pointer" onClick={() => setOpen(false)}>
            <span className="text-sm font-lato font-semibold text-dark-text dark:text-light-text mr-2">Close</span>
            <div className="flex justify-center items-center bg-[#E56060] text-[#E56060] bg-opacity-10 rounded-full w-[15px] h-[15px]">
              &#10005;
            </div>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-8">
          {/* Metamask */}
          <button
            className="flex focus:outline outline-primary-green py-2 px-6 flex-col items-center justify-center border shadow-md rounded-xl"
            key={wallets[0].id}
            onClick={onMetamask}
          >
            <img src={wallets[0].image} alt={wallets[0].name} className="w-[50px] h-[50px] mb-4" />
            <span className="font-lato font-semibold text-dark-text dark:text-light-text">{wallets[0].name}</span>
          </button>
          <button
            className="flex focus:outline outline-primary-green py-2 px-6 flex-col items-center justify-center border shadow-md rounded-xl"
            key={wallets[1].id}
            onClick={onConnect}
          >
            <img src={wallets[1].image} alt={wallets[1].name} className="w-[50px] h-[50px] mb-4" />
            <span className="font-lato font-semibold text-dark-text dark:text-light-text">{wallets[1].name}</span>
          </button>
        </div>

        <div className="flex justify-center mt-5">
          <span className="text-primary-green font-semibold font-lato">Learn how to connect</span>
        </div>
      </div>
    </Modal>
  );
}

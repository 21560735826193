import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../config/constants/LaunchpadAddress";
import { useNavigate } from 'react-router-dom';
import FaImage from '../../../svgs/New/Image';
import FaVideo from '../../../svgs/New/Video';
import SendSVG from '../../../svgs/New/Send';
import { useEthers } from '@usedapp/core';

const CreatePost = () => {
  const { account } = useEthers();
  const [description, setDescription] = useState("");
  const [contentType, setContentType] = useState("text");
  const [media, setMedia] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    username: "",
    avatar: "",
    bannerUrl: "",
    bio: "",
    followers: 0,
    following: 0,
    posts: [],
  });

  const [isVerified, setIsVerified] = useState(false);
  console.log('isVerified:', isVerified)
  const textareaRef = useRef(null);

  useEffect(() => {
    if (account) {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`${BACKEND_URL}/api/profile/${account}`);
          setUserData(response.data);
        } catch (error) {
          console.error("Failed to fetch profile:", error);
        }
      };

      const checkVerification = async () => {
          try {
              const normalizedWalletAddress = account.toLowerCase(); // Normalisasi
              console.log('account:', normalizedWalletAddress);
              const response = await axios.get(`${BACKEND_URL}/api/verify/${normalizedWalletAddress}`); // Gunakan yang dinormalisasi
              setIsVerified(response.data.isVerified); // Pastikan ini sesuai dengan respons dari backend
          } catch (error) {
              console.error("Failed to check verification:", error);
          }
      };


      fetchProfile();
      checkVerification();
    }
  }, [account]);

  const { username, avatar } = userData;

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    setMedia(file);
    setContentType(file.type.includes('video') ? 'video' : 'image');
  };

  const handleUploadToBackend = async () => {
    try {
      const formData = new FormData();
      formData.append('file', media);

      const response = await axios.post(`${BACKEND_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        },
      });

      const { image } = response.data;
      return image.fileUrl;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userData.username) {
      setModalMessage("Username not found. Please set your profile first. Set your profile here: Menu > Account > Profile > Edit profile");
      setIsModalOpen(true);
      return;
    }

    if (!isVerified) {
      setModalMessage("Verify your email first before you can make a post. verify your Email here: Menu > Account > Verify");
      setIsModalOpen(true);
      return;
    }

    let mediaUrl = "none";

    if (contentType !== "text" && media) {
      const uploadedUrl = await handleUploadToBackend();
      if (!uploadedUrl) {
        console.error("Failed to upload file to backend.");
        return;
      }
      mediaUrl = uploadedUrl;
    }

    const newPost = {
      description,
      contentType,
      media: mediaUrl,
      walletAddress: account.toLowerCase(),
      username,
    };

    try {
      await axios.post(`${BACKEND_URL}/api/posts/savePost`, newPost);
      setDescription("");
      setContentType("text");
      setMedia(null);
      navigate("/");
    } catch (error) {
      console.error("Failed to submit post:", error);
    }
  };

  const handleTextareaChange = (e) => {
    setDescription(e.target.value);
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  return (
    <div className="max-w-2xl mt-2 mb-2 mx-auto bg-stone-100 p-6 rounded-lg shadow-md">
      <form onSubmit={handleSubmit}>
        <div className="mb-4 ">
        <div className="flex ">
        <img src={avatar || "/images/Avatar.png"} alt={username} className="w-8 h-8 items-center mb-2 rounded-lg mr-2" />
        <p className="text-2xl font-lato items-center font-semibold"> {username || "Unknow"}</p>
        </div>
        <div className="flex items-center bg-[white] rounded-full">
          <textarea
            ref={textareaRef}
            className="w-full p-2 font-lato focus:outline-none text-sm rounded-lg overflow-hidden"
            style={{ whiteSpace: 'pre-wrap' }}
            value={description}
            onChange={handleTextareaChange}
            placeholder="What's happening?"
            rows={1}
            required
          />
          </div>
          {contentType === "image" && media && (
            <div className="mb-4">
              <img
                src={URL.createObjectURL(media)}
                alt="Preview"
                className="w-full rounded-lg"
              />
            </div>
          )}
          {contentType === "video" && media && (
            <div className="mb-4">
              <video controls className="w-full rounded-lg">
                <source src={URL.createObjectURL(media)} />
              </video>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
            <label className="flex items-center text-xs font-lato cursor-pointer">
              <FaImage size={24} className="mr-1"/>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleMediaChange}
              /> Image
            </label>
            <label className="flex items-center text-xs font-lato cursor-pointer">
              <FaVideo size={24} className="mr-1"/>
              <input
                type="file"
                className="hidden"
                accept="video/*"
                onChange={handleMediaChange}
              /> Video
            </label>
          </div>
          <button
            type="submit"
            className="flex bg-blue-500 text-white p-2 rounded-full font-semibold"
          >
            <SendSVG className="h-4 w-4"/>
          </button>
        </div>
      </form>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50"> {/* High z-index */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <p className="mb-4">{modalMessage}</p>
            <button
              onClick={closeModal}
              className="bg-blue-500 text-white p-2 rounded-lg font-semibold"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePost;

import React, { useState } from 'react';
import { ethers } from 'ethers'; // Menggunakan ethers untuk mengirim token
import BaseLayout from "../BaseLayout/BaseLayout";
import UpcomingVotes from "./UpcomingVotes";
import ActiveVotes from "./ActiveVotes";
import PassedVotes from "./PassedVotes";
import Sidebar from "../Content/Sidebar";

export default function GovernanceVotePage() {
  const proposals = [
    /* { id: 1, title: 'Proposal A', description: 'Increase staking rewards by 5%', status: 'upcoming', options: ['Setuju', 'Tidak Setuju'] },
    { id: 2, title: 'Proposal B', description: 'Reduce transaction fees by 2%', status: 'active', options: ['Setuju', 'Tidak Setuju'] },
    { id: 3, title: 'Proposal C', description: 'Implement new liquidity pool', status: 'passed', options: ['Setuju', 'Tidak Setuju'], result: 'Setuju' },*/
  ];

  const [activeTab, setActiveTab] = useState('upcoming');
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [voteSubmitted, setVoteSubmitted] = useState(false);

  // Fungsi untuk memulai voting
  const startVoting = (proposal) => {
    setSelectedProposal(proposal);
    setSelectedOption(null);
    setVoteSubmitted(false);
  };

  // Fungsi untuk menangani vote dan mengirim token
  const handleVote = async () => {
    if (selectedOption !== null && selectedProposal) {
      try {
        // Alamat contract token (ganti dengan yang sesuai)
        const contractAddress = '0xYourContractAddressHere';
        const abi = [ // ABI untuk fungsi transfer atau vote
          "function transfer(address to, uint amount) public returns (bool)"
        ];

        // Meminta pengguna untuk terhubung dengan MetaMask
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        // Jumlah token yang dikirim, misal 1 token untuk setiap vote
        const amount = ethers.utils.parseUnits("1.0", 18); // Ganti dengan jumlah token dan decimal yang benar

        // Mengirim token ke contract
        const tx = await contract.transfer(contractAddress, amount);
        await tx.wait(); // Menunggu transaksi selesai

        setVoteSubmitted(true);
        console.log(`Anda memilih opsi: ${selectedOption} untuk proposal ${selectedProposal.title}`);
      } catch (error) {
        console.error("Gagal mengirim token: ", error);
        alert("Gagal mengirim vote. Silakan coba lagi.");
      }
    } else {
      alert('Silakan pilih opsi terlebih dahulu!');
    }
  };

  // Fungsi untuk menutup modal
  const closeModal = () => {
    setSelectedProposal(null);
  };

  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row">
        {/* Konten Utama */}
        <div className="w-full lg:w-3/5 p-4">
          <div className="flex flex-col items-center justify-center bg-gray-100 p-4">
            <div className="w-full max-w-4xl">
              {/* Tab Navigation */}
              <div className="flex mb-6 font-lato">
                <button
                  onClick={() => setActiveTab('upcoming')}
                  className={`px-4 py-2 mr-2 rounded-lg ${activeTab === 'upcoming' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                >
                  Upcoming
                </button>
                <button
                  onClick={() => setActiveTab('active')}
                  className={`px-4 py-2 mr-2 rounded-lg ${activeTab === 'active' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                >
                  Live
                </button>
                <button
                  onClick={() => setActiveTab('passed')}
                  className={`px-4 py-2 rounded-lg ${activeTab === 'passed' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                >
                  Passed
                </button>
              </div>

              {/* Tab Content */}
              {activeTab === 'upcoming' && <UpcomingVotes proposals={proposals} />}
              {activeTab === 'active' && <ActiveVotes proposals={proposals} startVoting={startVoting} />}
              {activeTab === 'passed' && <PassedVotes proposals={proposals} />}
            </div>
          </div>

          {/* Voting Modal */}
          {selectedProposal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-md font-lato">
                <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                  &times;
                </button>

                <h2 className="text-xl font-semibold mb-4">Vote untuk: {selectedProposal.title}</h2>
                {selectedProposal.options.map((option, index) => (
                  <div key={index} className="mb-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="voteOption"
                        value={option}
                        onChange={() => setSelectedOption(option)}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">{option}</span>
                    </label>
                  </div>
                ))}
                <button
                  onClick={handleVote}
                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Submit Vote
                </button>

                {voteSubmitted && (
                  <p className="mt-4 text-green-500 font-semibold">Vote berhasil dikirim!</p>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Sidebar */}
        <div className="hidden lg:block lg:w-2/5 p-4">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
}

import React, { useState } from 'react';
import { useEthers } from '@usedapp/core';
import { toast } from 'react-toastify';
import BaseLayout from "../BaseLayout/BaseLayout";
import Sidebar from "../Content/Sidebar";

const tiers = [
  {
    name: 'Free',
    badge: '', // No badge for Free tier
    cost: 0,
    benefits: [
      'No Badge.',
      'MXP Exchange Rate 1:50',
    ],
  },
  {
    name: 'Bronze',
    badge: '/images/badges/01.png',
    cost: 1000,
    benefits: [
      'Get Bronze Badge (lifetime)',
      'MXP Exchange Rate 1:20',
    ],
  },
  {
    name: 'Silver',
    badge: '/images/badges/02.png',
    cost: 5000,
    benefits: [
      'Get Silver Badge (lifetime)',
      'MXP Exchange Rate 1:15',
    ],
  },
  {
    name: 'Gold',
    badge: '/images/badges/03.png',
    cost: 10000,
    benefits: [
      'Get Gold Badge (lifetime)',
      'MXP Exchange Rate 1:10',
    ],
  },
  {
    name: 'Platinum',
    badge: '/images/badges/04.png',
    cost: 20000,
    benefits: [
      'Get Platinum Badge (lifetime)',
      'MXP Exchange Rate 1:1',
    ],
  },
];

export default function SubscriptionPage() {
  const { account, library } = useEthers();
  const [showModal, setShowModal] = useState(false);
  const [currentTier, setCurrentTier] = useState(null);

  const handleSubscribe = async () => {
    if (!account) {
      toast.error('Please connect your wallet first.');
      return;
    }

    try {
      const amount = currentTier.cost;
      const tx = await library.getSigner().sendTransaction({
        to: 'ADDRESS_CONTRACT_TOKEN', // Replace with the token contract address
        value: library.utils.parseEther(amount.toString()),
      });

      await tx.wait();
      toast.success(`Successfully exchanged ${amount} MEET for ${currentTier.name} tier!`);
      setShowModal(false);
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while processing the transaction.');
    }
  };

  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row">
        {/* Main Content */}
        <div className="w-full lg:w-3/5 p-4">
          <h1 className="font-lato text-2xl font-bold mb-5">Choose Subscription Tier</h1>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
            {tiers.map((tier) => (
              <div key={tier.name} className="border rounded-lg p-4 shadow hover:shadow-lg">
                <h2 className="items-center flex font-lato font-semibold text-lg mb-2">
                  {tier.name}
                  {tier.badge && <img src={tier.badge} alt={`${tier.name} Badge`} className="ml-2 w-6 h-6" />}
                </h2>
                <ul className="font-lato text-sm list-disc ml-5 mb-2">
                  {tier.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
                <p className="font-lato font-bold text-sm">{tier.cost} MEET</p>
                {tier.cost > 0 && (
                  <button
                    onClick={() => {
                      setCurrentTier(tier);
                      setShowModal(true);
                    }}
                    className="text-sm font-lato bg-blue-500 text-white px-4 py-1 rounded mt-2"
                  >
                    Purchase
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Sidebar */}
        <div className="hidden lg:block lg:w-2/5 p-4">
          <Sidebar />
        </div>
      </div>

      {/* Modal for purchase confirmation */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded shadow-md border-2">
            <h2 className="font-lato text-xl font-semibold mb-4">Confirm Purchase</h2>
            <p>You will exchange {currentTier.cost} MEET for {currentTier.name} tier.</p>
            <div className="mt-4 flex justify-end">
              <button onClick={() => setShowModal(false)} className="mr-2 px-4 py-2 border rounded">
                Cancel
              </button>
              <button onClick={handleSubscribe} className="bg-blue-500 text-white px-4 py-2 rounded">
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </BaseLayout>
  );
}

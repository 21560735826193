import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 18h3.75a5.25 5.25 0 1 0 0-10.5H5M7.5 4 4 7.5 7.5 11"
    />
  </svg>
);
export default SVGComponent;

import React, { useMemo, useEffect, useState } from "react";
import { useEthers, useEtherBalance, useTokenBalance } from "@usedapp/core";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import { formatUnits } from "@ethersproject/units";
import BaseLayout from "../../BaseLayout/BaseLayout";
import GoBackIcon from "../../../svgs/New/goback";
import AddIcon from "./Svgs/Add";
import SentIcon from "./Svgs/Sent";
import RequestIcon from "./Svgs/Request";
import BridgeIcon from "./Svgs/Bridge";
import WalletIcon from "./Svgs/Wallet";
import Sidebar from "../../Content/Sidebar";
import BalanceChart from "./Chart";
import { useTokenPrices } from '../../../hooks/useTokenPrice';

// Token list
const TOKEN_LIST = [
  {
    name: "Ethereum",
    symbol: "ETH",
    logo: "https://seeklogo.com/images/E/ethereum-logo-EC6CDBA45B-seeklogo.com.png",
    contractAddress: null, // null for native token
  },
  {
    name: "Tether",
    symbol: "USDT",
    logo: "https://cryptologos.cc/logos/tether-usdt-logo.png",
    contractAddress: "0xfde4c96c8593536e31f229ea8f37b2ada2699bb2", // Ethereum USDT contract address
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
    contractAddress: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913", // Ethereum USDC contract address
  },
  {
    name: "Meet Finance",
    symbol: "MEET",
    logo: "/images/logo512.png", // Replace with actual MeFi logo URL
    contractAddress: "", // Replace with actual MeFi contract address
  },
];

const SYMBOL_TO_KEY = {
  ETH: "ethereum",
  USDT: "tether",
  USDC: "usd-coin",
  MEFI: "mefi" // Pastikan MEFI ada dalam tokenPrices atau atur harga default
};

const ProfilePage = () => {
  const { walletAddress } = useParams();
  const { account } = useEthers();
  console.log(account)
  const etherBalance = useEtherBalance(account);
  const tokenPrices = useTokenPrices();
  const normalizedWalletAddress = walletAddress.toLowerCase();
  const [userData, setUserData] = useState({ username: 'Anonymous', avatar: 'https://via.placeholder.com/40' });

  useEffect(() => {
    if (account) {
      // Menggunakan akun dari useEthers
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`${BACKEND_URL}/api/profile/${account}`);
          setUserData({
            username: response.data.username || 'Anonymous',
            avatar: response.data.avatar || 'https://via.placeholder.com/40'
          });
        } catch (error) {
          console.error("Failed to fetch profile data:", error);
        }
      };

      fetchUserData();
    }
  }, [account]);

  // Fetch token balances for each token
  const usdtBalance = useTokenBalance(TOKEN_LIST[1].contractAddress, account);
  const usdcBalance = useTokenBalance(TOKEN_LIST[2].contractAddress, account);
  const mefiBalance = useTokenBalance(TOKEN_LIST[3].contractAddress, account);

  const tokenBalances = {
    ETH: etherBalance,
    USDT: usdtBalance,
    USDC: usdcBalance,
    MEFI: mefiBalance,
  };

  // Calculate total USD balance
  const totalUsdBalance = useMemo(() => {
    return TOKEN_LIST.reduce((total, token) => {
      const tokenKey = SYMBOL_TO_KEY[token.symbol] || token.symbol.toLowerCase();
      const priceData = tokenPrices ? tokenPrices[tokenKey] : null;

      // Check if price data exists and is not null
      const price = priceData?.usd ?? 0;
      const balance = tokenBalances[token.symbol]
        ? parseFloat(formatUnits(tokenBalances[token.symbol], token.symbol === "ETH" ? 18 : (token.symbol === "MEFI" ? 18 : 6)))
        : 0;

      return total + price * balance;
    }, 0);
  }, [tokenBalances, tokenPrices]);

  const goBack = () => {
    window.history.back();
  };

  if (!tokenPrices) {
    return <p>Loading...</p>;
  }

  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row min-h-screen bg-[#f1f5f9] mb-10">
        <div className="w-full lg:w-3/6 p-4 border-2 border-gray rounded-xl mt-4 md:ml-2 bg-white">
          <button onClick={goBack} className="flex items-center text-xl text-black font-bold mb-6">
            <GoBackIcon /> <span className="ml-2">Profile</span>
          </button>

          <div className="home-howbg rounded-xl mt-6 items-center  px-4 py-2">
          <div className="relative w-full flex items-center justify-left mb-4">
            <img src={userData.avatar} alt={userData.username} className="w-12 h-12 rounded-full bg-white border-4 border-white mr-2" />
            <p className="text-lg font-lato font-semibold"> {userData.username} </p>
          </div>
            <p className="text-sm font-lato"> Wallet Balance </p>
            <p className="text-3xl font-lato"> ${totalUsdBalance.toFixed(4)} </p>
          </div>
          {/* <BalanceChart tokenBalances={tokenBalances} tokenPrices={tokenPrices} /> */}
          <div className="flex justify-between items-center mb-6 px-4 py-2">
            <div className="flex flex-col items-center text-center">
              <AddIcon className="w-8 h-8 border-2 border-black rounded-full p-2" />
              <p>Add</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <SentIcon className="w-8 h-8 border-2 border-black rounded-full p-2" />
              <p>Sent</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <RequestIcon className="w-8 h-8 border-2 border-black rounded-full p-2" />
              <p>Request</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <BridgeIcon className="w-8 h-8 border-2 border-black rounded-full p-2" />
              <p>Bridge</p>
            </div>
          </div>


          <div className="mt-6 bg-[#f1f5f9] border-wallet px-2 py-4">
          <h1 className="flex items-center text-lg font-lato font-semibold mb-2"><WalletIcon className="w-6 h-6 mr-1" /> Portfolio </h1>
            {TOKEN_LIST.map((token) => {
              const tokenKey = SYMBOL_TO_KEY[token.symbol] || token.symbol.toLowerCase();
              const priceData = tokenPrices ? tokenPrices[tokenKey] : null;
              const price = priceData?.usd ?? 0;
              const balance = tokenBalances[token.symbol]
                ? parseFloat(formatUnits(tokenBalances[token.symbol], token.symbol === "ETH" ? 18 : (token.symbol === "MEFI" ? 18 : 6)))
                : 0;
              const totalValue = price * balance;

              return (
                <div key={token.symbol} className="flex items-center font-lato justify-between py-2 shadow-md border mb-2 px-4 rounded-xl border-gray-200">

                  <div className="flex items-center">
                    <img src={token.logo} alt={token.symbol} className="w-8 h-8 mr-4" />
                    <div>
                      <p className="text-lg font-lato font-semibold">{token.name}</p>
                      <p className="text-sm font-lato text-gray-600">{token.symbol}</p>
                    </div>
                  </div>
                  <div>
                    <p>{balance.toFixed(4)}</p>
                    <p className="text-sm font-lato text-gray-600">${totalValue.toFixed(2)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="hidden lg:block lg:w-3/6 p-4">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default ProfilePage;

import * as React from "react";
const SVGComponent = (props) => (
  <svg
  width={24}
  height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 458.139 458.139"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M428.139 13.979H138.495c-16.569 0-30 13.431-30 30v80.993h211.149c33.084 0 60 26.916 60 60v72.4h48.495c16.568 0 30-13.431 30-30V43.979c0-16.568-13.431-30-30-30" />
    <path d="M319.644 154.968H30c-16.569 0-30 13.431-30 30v183.393c0 16.569 13.431 30 30 30h92.333l29.531 35.11a30 30 0 0 0 45.918 0l29.531-35.11h92.333c16.569 0 30-13.431 30-30V184.968c-.002-16.568-13.434-30-30.002-30m-171.32 167.216H70.161c-8.284 0-15-6.716-15-15s6.716-15 15-15h78.164c8.284 0 15 6.716 15 15-.001 8.285-6.717 15-15.001 15m123.187-62.034H70.161c-8.284 0-15-6.716-15-15s6.716-15 15-15h201.351c8.284 0 15 6.716 15 15-.001 8.284-6.716 15-15.001 15" />
  </svg>
);
export default SVGComponent;

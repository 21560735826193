import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import BaseLayout from '../BaseLayout/BaseLayout';
import CreateSVG from '../../svgs/New/Create';
import NewsSVG from '../../svgs/New/News';
import Sidebar from "../Content/Sidebar";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import TrashIcon from '../../svgs/New/Trash';  // Tambahkan import ikon Trash

const Announcement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const adminAddress = "0x950b486A7D745ca802adC414F96B6320713514c0";
  const { account } = useEthers();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/news/all-news`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAnnouncements(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/news/news/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Gagal menghapus berita');
      }
      setAnnouncements(announcements.filter(announcement => announcement.id !== id));
    } catch (error) {
      console.error('Error deleting news:', error);
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const sortedAnnouncements = announcements.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const truncateTitle = (title) => {
    return title.length > 30 ? `${title.substring(0, 25)}...` : title;
  };

  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row min-h-screen bg-[#f1f5f9] mb-10">
        <div className="w-full lg:w-4/6 md:p-4  rounded-xl">
          <div className="py-4 md:px-4 md:py-4 py-2 px-2 border-2 border-gray rounded-xl bg-white">
            <div className="flex justify-between font-lato items-center mb-4">
              <h2 className="flex items-center text-2xl px-2 font-bold ">
                News
              </h2>
              {account && account.toLowerCase() === adminAddress.toLowerCase() && (
                <Link to="/CreateNews" className="flex px-4 py-1 items-center text-md border border-gray-100 rounded-full bg-[#38bdf8]">
                  <CreateSVG className="mr-1"/> Create
                </Link>
              )}
            </div>
            <div className="grid grid-cols-1 font-lato sm:grid-cols-2 gap-4">
              {sortedAnnouncements.map((announcement) => (
                <div
                  key={`${announcement.id}-${announcement.createdAt}`}
                  className="border rounded-lg shadow-lg p-4 bg-white"
                >
                  <img
                    src={announcement.banner}
                    alt={announcement.title}
                    className="w-full h-40 object-cover rounded-md mb-4"
                  />
                  <p className="text-[#0ea5e9] text-md text-sm">
                    Meet Finance
                  </p>
                  <p className="text-[#0ea5e9] text-md text-sm mb-2">
                    <span className="text-xs font-lato">{formatDate(announcement.createdAt)}</span>
                  </p>
                  <div className="flex justify-between items-center">
                  <Link
                    to={`/announcement/${announcement.id}`}
                    className="text-lg font-lato font-semibold text-black hover:underline mb-2"
                  >
                    {truncateTitle(announcement.title)}
                  </Link>
                  {/* Tampilkan ikon trash hanya jika user adalah admin atau pembuat berita */}
                  {account && (account.toLowerCase() === adminAddress.toLowerCase() || account.toLowerCase() === announcement.admin.toLowerCase()) && (
                    <button
                      className="items-center"
                      onClick={() => handleDelete(announcement.id)}
                    >
                      <TrashIcon className=" h-5 w-5"/>
                    </button>
                  )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="hidden lg:block lg:w-2/6 p-4">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default Announcement;

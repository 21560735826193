import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g />
    <path d="M26.129 5.871h-5.331V4.805a2.13 2.13 0 0 0-2.133-2.132h-5.331a2.133 2.133 0 0 0-2.133 2.132v1.066H5.87v1.066h1.099l1.067 20.259c0 1.178.955 2.133 2.133 2.133h11.729a2.133 2.133 0 0 0 2.133-2.133L25.08 6.937h1.051V5.871zM12.268 4.804c0-.588.479-1.066 1.066-1.066h5.331c.588 0 1.066.478 1.066 1.066V5.87h-7.464zM22.966 27.14l-.002.027v.028c0 .587-.478 1.066-1.066 1.066H10.169a1.07 1.07 0 0 1-1.066-1.066v-.028l-.001-.028L8.037 6.936h15.975z" />
    <path d="M15.467 9.069h1.066v17.06h-1.066zm-2.109 17.026L12.267 9.068l-1.064.068 1.091 17.027zm7.447-16.992-1.064-.067-1.076 17.06 1.064.067z" />
  </svg>
);
export default SVGComponent;

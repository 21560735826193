// Modal.js
import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      {/* Background overlay untuk modal */}
      <div
        className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50"
        onClick={onClose}
      ></div>

      {/* Konten modal */}
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded shadow-lg w-full max-w-lg relative">
          {/* Tombol close "X" */}
          <button
            className="absolute top-2 right-2 text-gray-600 text-xl font-bold"
            onClick={onClose}
          >
            &times;
          </button>
          {children}
        </div>
      </div>
    </>,
    document.body
  );
};

export default Modal;

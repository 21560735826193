import React, { useState } from "react";

const CommentForm = ({ onSubmit }) => {
  const [commentText, setCommentText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (commentText) {
      onSubmit(commentText);
      setCommentText("");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4 flex space-x-2 mb-4">
      <input
        type="text"
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        placeholder="Add a comment..."
        className="flex-grow px-2 py-2 font-lato rounded-lg border border-gray-300"
      />
      <button type="submit" className="text-white text-sm font-lato px-6 py-1 rounded-full bg-black">Send</button>
    </form>
  );
};

export default CommentForm;

import { useEffect, useState } from 'react';

export const useTokenPrices = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Mengambil harga untuk ethereum, usdt, dan usdc
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum,tether,usd-coin&vs_currencies=usd'
        );
        const responseData = await response.json();

        setData(responseData);
      } catch (error) {
        console.error('Unable to fetch data:', error);
      }
    };

    fetchData();
  }, [setData]);

  return data;
};

import React, { useState } from 'react';
import Launchpads from './Launchpads';
import BaseLayout from "../BaseLayout/BaseLayout";


const Index = () => {
  const [activeTab, setActiveTab] = useState('Live');

  return (
    <BaseLayout>
    <div className="items-center py-8 px-4 md:px-8">
    <div
      className="w-full flex flex-col-2 rounded-3xl mb-9 md:mb-20"
      style={{
        backgroundImage: `url("https://img.freepik.com/free-vector/halftone-background-with-circles_23-2148907689.jpg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // or 'contain' based on what you want
        backgroundPosition: "center", // Optional: centers the background image
      }}
    >
    <div className="w-1/2 w-full px-2 md:px-12 py-8 content-center flex-start">
    <p className="md:text-5xl text-xl px-2 font-lato items-center text-white font-bold mb-2"> Invest in your trusted projects</p>
    </div>
    <div className="w-1/2 w-full flex flex-col items-center">
    <img
    src="https://static.vecteezy.com/system/resources/previews/022/996/345/non_2x/3d-space-rocket-render-with-transparent-background-free-png.png"
    alt="banner"
    className="md:h-80 md:w-80 h-30 w-30 translate-y-1/4 md:translate-y-1/4" />
    </div>
    </div>
    <div className="flex justify-between items-center">
      <h1 className="text-xl font-bold justify-left font-lato text-gray-800 mb-8">Projects</h1>

      {/* Tabs */}
      <div className="flex md:space-x-2 space-x-1 mb-8 text-xs border bg-gray-300 rounded-lg">
        <button
          className={`px-4 py-2 rounded-lg font-bold ${activeTab === 'Live' ? 'bg-white text-black' : 'bg-gray-300 text-gray-700'}`}
          onClick={() => setActiveTab('Live')}
        >
          Live
        </button>
        <button
          className={`px-4 py-2 rounded-lg font-bold ${activeTab === 'Upcoming' ? 'bg-white text-black' : 'bg-gray-300 text-gray-700'}`}
          onClick={() => setActiveTab('Upcoming')}
        >
          Upcoming
        </button>
        <button
          className={`px-4 py-2 rounded-lg font-bold ${activeTab === 'Ended' ? 'bg-white text-black' : 'bg-gray-300 text-gray-700'}`}
          onClick={() => setActiveTab('Ended')}
        >
          Ended
        </button>
      </div>
      </div>

      {/* Render Launchpads based on activeTab */}
      <Launchpads status={activeTab} />
    </div>
    </BaseLayout>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardSVG from 'svgs/Sidebar/dashboard';
import Web3 from "web3";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import Post from "../../components/Content/Post";

const PostList = ({ walletAddress }) => {
  const [posts, setPosts] = useState([]);
  const [activeTab, setActiveTab] = useState("myPosts");
  const [isOwnProfile, setIsOwnProfile] = useState(false);


  const fetchPosts = async (endpoint) => {
    if (!walletAddress) return;

    try {
      const response = await axios.get(endpoint, {
        params: { walletAddress },
      });
      const sortedPosts = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Mengurutkan postingan dari yang terbaru
      setPosts(sortedPosts);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  useEffect(() => {
    // Menentukan apakah profil yang dilihat adalah milik pengguna sendiri
    const checkIfOwnProfile = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          const accounts = await web3.eth.getAccounts();
          const currentAddress = accounts[0];
          if (walletAddress === currentAddress) {
            setIsOwnProfile(true);
          }
        } catch (error) {
          console.error("Failed to get accounts:", error);
        }
      }
    };

    checkIfOwnProfile();
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      const endpoint =
        activeTab === "myPosts"
          ? `${BACKEND_URL}/api/posts/getPostByWallet`
          : `${BACKEND_URL}/api/posts/getFavoritePosts`;
      fetchPosts(endpoint);
    }
  }, [walletAddress, activeTab]);

  return (
    <div className="mt-4 px-2 py-2">
      <div className="flex border-b items-center">
        <button
          onClick={() => setActiveTab("myPosts")}
          className={`p-2 flex text-center ${
            activeTab === "myPosts" ? "border-b-2 border-blue-500 font-bold" : ""
          }`}
        >
          <DashboardSVG className="w-5 h-5 mr-1"/>Feeds
        </button>
        {isOwnProfile && (
          <button
            onClick={() => setActiveTab("favorites")}
            className={`p-2 flex-1 text-center ${
              activeTab === "favorites" ? "border-b-2 border-blue-500 font-bold" : ""
            }`}
          >
            Favorites
          </button>
        )}
      </div>
      <div className="mt-4">
        {posts.length > 0 ? (
          posts.map((post) => (
            <Post key={post.id} post={post} onProfileClick={() => {}} />
          ))
        ) : (
          <p className="text-gray-600">No posts available.</p>
        )}
      </div>
    </div>
  );
};

export default PostList;

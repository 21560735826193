import LaunchpadSVG from 'svgs/Sidebar/launchpad';
import AirplaneSVG from '../svgs/Sidebar/airplane';
import DashboardSVG from '../svgs/Sidebar/dashboard';
import MarketSVG from '../svgs/New/Market';
import LockerSVG from '../svgs/Sidebar/locker';
import MoreProductsSVG from '../svgs/Sidebar/more_products';
import SheildSecuritySVG from 'svgs/Sidebar/shield_security';
import StakingSVG from 'svgs/Sidebar/staking';
import TradeSVG from 'svgs/Sidebar/trade';
import AirdropSVG from 'svgs/Sidebar/Airdrop';
import PdfSVG from 'svgs/Sidebar/PDF';
import VoteSVG from '../svgs/New/Vote';
import VerifySVG from '../svgs/New/Verify';
import NewsSVG from '../svgs/New/News';
import PremiumSVG from '../svgs/New/Premium';
import WalletIcon from "components/Profile/Wallet/Svgs/Wallet";
import { useEthers } from '@usedapp/core';

// Custom hook or function to get the sitemap
export function useSitemap() {
  const { account } = useEthers(); // Move useEthers into a React function or custom hook

  return [
    {
      id: 1,
      name: 'Feeds',
      extendable: false,
      icon: <DashboardSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
      activeIcon: <DashboardSVG className="fill-primary-green" />,
      link: '/',
      sublinks: ['/'],
    },
    {
      id: 4,
      name: 'News',
      extendable: false,
      icon: <NewsSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
      activeIcon: <NewsSVG className="fill-primary-green" />,
      link: '/announcements',
      sublinks: [],
    },
    {
      id: 3,
      name: 'Forum',
      extendable: true, // Set to true to indicate it has sublinks
      icon: <StakingSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
      activeIcon: <StakingSVG className="fill-primary-green" />,
      link: '/community',
      sublinks: [
        { name: 'General Chat', link: '/community', icon: <StakingSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" /> },
        { name: 'Vote', link: '/vote', icon: <VoteSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" /> },
      ],
    },
    {
      id: 4,
      name: 'Launchpad',
      extendable: false,
      icon: <AirplaneSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
      activeIcon: <AirplaneSVG className="fill-primary-green" />,
      link: '/exchange',
      sublinks: [],
    },
    // {
    //   id: 2,
    //   name: 'Account',
    //   extendable: true,
    //   icon: <TradeSVG className="fill-dim-text dark:fill-dim-text-dark scale-[1.2] hover:fill-primary-green" style={{ paddingLeft: '1.9px', width: '22px' }} />,
    //   activeIcon: <TradeSVG className="fill-primary-green scale-[1.2]" />,
    //   link: `/account/${account}`, // Use account value here
    //   sublinks: [
    //     { name: 'Profile', link: `/account/${account}`, icon: <TradeSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" /> },
    //     { name: 'Wallet', link: `/portfolio/${account}`, icon: <WalletIcon className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" /> },
    //     { name: 'Premium', link: '/Subscription', icon: <PremiumSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" /> },
    //     { name: 'Verify', link: `/register/${account}`, icon: <VerifySVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" /> },
    //   ],
    // },
    {
      id: 6,
      name: 'About',
      extendable: false,
      icon: <AirdropSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
      activeIcon: <AirdropSVG className="fill-primary-green" />,
      link: '/about',
      sublinks: [],
    },
  ];
}

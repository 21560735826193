import { useContext, useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import axios from 'axios';
import { BACKEND_URL } from "config/constants/LaunchpadAddress";

export default function Online() {
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/total`); // Ganti dengan URL lengkap jika diperlukan
        setTotalUsers(response.data.totalUsers);
      } catch (err) {
        console.error('Error fetching total users:', err.message);
      }
    };
    fetchTotalUsers();
  }, []);

  return (
    <div>
        <p className="px-4 py-2 font-lato"><span className="pulse mr-2 text-xs">🟢</span>{totalUsers} Members </p>
    </div>
  );
}

import React from 'react'
import { useModal } from 'react-simple-modal-provider'
import UserSVG from '../SettingIcon'

const DisconnectedButton = () => {
  const { open: openModal } = useModal('ConnectionModal')
  return (
    <button className="ml-6 p-1 bg-primary-green rounded-md border-2 border-gray-500 flex items-center focus:outline-none" onClick={openModal}>
      <UserSVG className="fill-black mr-2" />
      <span className="font-lato font-semibold rounded-lg text-[black]">
        Signin
      </span>
    </button>
  )
}

export default DisconnectedButton

import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m21.945 14.472.021.062.023.1.01.1v6.516a.75.75 0 0 1-.65.743l-.1.007H2.75a.75.75 0 0 1-.743-.648L2 21.25v-6.5l.002-.052.01-.086a1 1 0 0 1 .047-.153l2.76-6.019a.75.75 0 0 1 .573-.43l.108-.007 2.54-.001-.79 1.37-.067.13H5.98L3.918 14H20.07l-2.027-4.346.862-1.497q.101.076.172.184l.053.095zm-8.58-12.416.092.045 5.188 3.003c.328.19.458.591.319.933l-.045.092L16.112 11h1.138a.75.75 0 0 1 .102 1.494l-.102.007-2.002-.001v.003h-4.079l-.003-.003H6.75a.75.75 0 0 1-.102-1.492l.102-.007L8.573 11l-.182-.105a.75.75 0 0 1-.318-.933l.044-.092 4.317-7.496c.19-.329.59-.46.931-.32m-.01 1.72L9.789 9.97 11.567 11h2.817l2.865-4.973z"
      fill="#212121"
    />
  </svg>
);
export default SVGComponent;

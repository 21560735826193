import { useState, useEffect } from 'react';
import { Base } from 'config/constants/testchain';

export function useDefaultChainId() {
  const [defaultChainId, setDefaultChainId] = useState(() => {
    const storedChainId = localStorage.getItem('network-switch');
    if (storedChainId) {
      const items = JSON.parse(storedChainId);
      const activeItem = items.find((item) => item.isActive);

      if (activeItem && activeItem.title === 'BASE') {
        return Base.chainId;
      }
    }

    return Base.chainId;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const network = localStorage.getItem('network-switch');
      if (network) {
        const items = JSON.parse(network);
        const activeItem = items.find((item) => item.isActive);

        if (activeItem && activeItem.title === 'BASE') {
          setDefaultChainId(Base.chainId);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return defaultChainId;
}

import React from 'react';
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import Header from './Components/Header';
import HowItWork from './Components/HowIt';
import KeyFeatures from './Components/KeyFeatures';
import About from './Components/About';
import Mission from './Components/Missin';
import Tokenomics from './Components/Tokenomics';
import Works from './Components/HowItWork';
import Roadmap from './Components/Roadmap';
import Footer from './Components/Footer';

const HomePage = () => {
  return (
    <BaseLayout>
      <Header />
      <HowItWork />
      <About />
      <Mission />
      <KeyFeatures />
      {/* <Tokenomics />
      <Roadmap /> */}
      <Footer />
    </BaseLayout>
  );
}

export default HomePage;

import React from 'react';
import { useRoutes } from 'react-router-dom';
import Error from '../pages/Error';
import ContentPage from 'components/Content/HomePage';
import AboutPage from 'pages/Home/HomePage';
import CreatePage from 'components/Content/Create/CreateContent';
import Profile from '../components/Profile/Profile';
import ChatGroup from 'pages/ChatPage/ChatPage';
import PostDetail from '../components/Content/PostDetail';
import Portfolio from '../components/Profile/Wallet';
import Market from '../components/Market';
import VotePage from '../components/Governance';
import SubcriptPage from '../components/Subcript';
import RegisterPage from '../components/Profile/Register';
import Announcement from 'components/News/Ann';
import AnnouncementDetails from 'components/News/AnnDetails';
import CreateNews from 'components/News/Create';

export default function WebRouter() {
  let routes = useRoutes([
    { path: '/', element: <ContentPage /> },
    { path: '/account', element: <Profile /> },
    { path: '/community', element: <ChatGroup /> },
    { path: '/post/:id', element: <PostDetail /> },
    { path: '/account/:walletAddress', element: <Profile /> },
    { path: '/portfolio/:walletAddress', element: <Portfolio /> },
    { path: '/about', element: <AboutPage /> },
    { path: '/exchange', element: <Market /> },
    { path: '/Vote', element: <VotePage /> },
    { path: '/Subscription', element: <SubcriptPage /> },
    { path: '/register/:walletAddress', element: <RegisterPage /> },
    { path: '/CreateNews', element: <CreateNews /> },
    { path: '/announcements', element: <Announcement /> }, // Route for announcements
    { path: '/announcement/:id', element: <AnnouncementDetails /> }, // Route for announcement details
    { path: '*', element: <Error /> },
  ]);

  return routes;
}

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useEthers } from '@usedapp/core';
import axios from "axios";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import Sidebar from "../Content/Sidebar";
import ChatSVG from '../../svgs/New/Chat';
import ReplySVG from '../../svgs/New/Reply';
import LikeSVG from '../../svgs/New/Like';
import DeleteSVG from '../../svgs/New/Trash';
import SendSVG from '../../svgs/New/Send';

const ChatGroup = () => {
  const { account } = useEthers();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [wallets, setWallets] = useState([]);
  console.log('wallets:', wallets)
  console.log('messages:',messages)

  useEffect(() => {
    if (account) {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`${BACKEND_URL}/api/profile/${account}`);
          setUserProfile(response.data);
          if (!response.data.username) {
            alert("Username not found. Please set up your profile on the /profile page.");
          }
        } catch (error) {
          console.error("Failed to fetch profile:", error);
          setError("Failed to load profile.");
        }
      };
      fetchProfile();
    }
  }, [account]);

  const fetchWallets = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/api/wallets`);
      setWallets(response.data); // Store wallet data in state
    } catch (error) {
      console.error("Failed to fetch wallet addresses:", error);
      setError("Failed to load wallet addresses.");
    }
  };

  useEffect(() => {
    fetchWallets();
  }, []); // Pastikan ini dipanggil pada mount

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/chat/messages`);
        setMessages(response.data.reverse());
      } catch (error) {
        console.error("Failed to load messages:", error);
        setError("Failed to load messages. Please try again.");
      }
    };
    loadMessages();
  }, []);

  const handleProfileClick = (walletAddress) => {
    if (walletAddress) {
      navigate(`/account/${walletAddress}`);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!account) {
      alert("Anda perlu terhubung dengan akun sebelum mengirim pesan.");
      return;
    }
    if (newMessage.trim() === "") {
      alert("Pesan tidak boleh kosong");
      return;
    }

    try {
      const username = userProfile.username;

      const response = await axios.post(`${BACKEND_URL}/api/chat/send`, {
        message: newMessage,
        walletAddress: account,
      });

      if (response.data.success) {
        setMessages([...messages, {
          walletAddress: account,
          message: newMessage,
          createdAt: new Date().toISOString(),
          likes: 0,
          replies: []
        }]);
        setNewMessage("");
      } else {
        alert("Gagal mengirim pesan.");
      }
    } catch (error) {
      console.error("Gagal mengirim pesan:", error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await axios.delete(`${BACKEND_URL}/api/chat/delete/${messageId}`);
      if (response.data.success) {
        setMessages(messages.filter(msg => msg.id !== messageId));
      } else {
        alert("Gagal menghapus pesan.");
      }
    } catch (error) {
      console.error("Gagal menghapus pesan:", error);
    }
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const postDate = new Date(date);
    const timeDiff = now - postDate;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 5) {
      const options = { month: 'short', day: '2-digit' };
      return postDate.toLocaleDateString(undefined, options);
    } else if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} second`;
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleReply = (username) => {
    setNewMessage(`@${username}: `);
  };

  const handleLike = async (messageId) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/api/chat/like/${messageId}`);
      if (response.data.success) {
        setMessages(messages.map(msg =>
          msg.id === messageId ? { ...msg, likes: response.data.likes } : msg
        ));
      }
    } catch (error) {
      console.error("Gagal menambahkan like:", error);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-white mb-10">
      <div className="mb-6 xl:w-3/5 p-2 md:p-4 mt-2">
        <h2 className="flex items-center text-2xl font-bold mb-4 font-lato"><ChatSVG className="mr-2"/>General Chat</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="border border-gray-300 p-4 rounded-lg mb-2 h-96 md:h-screen overflow-y-auto flex flex-col">
          {messages.map((msg, index) => {
            // Find the matching wallet
            const walletData = wallets.find(wallet => wallet.walletAddress.toLowerCase() === msg.walletAddress.toLowerCase()) || {};


                // Log walletData for debugging
                console.log("Wallet Data:", walletData);

                const avatar = walletData.avatar || "/default-avatar.png"; // Use default avatar if not found
                const username = walletData.username || msg.walletAddress;
                const isCurrentUser = msg.walletAddress === account;

                // Log avatar and username for debugging
                console.log("Avatar:", avatar);
                console.log("Username:", username);

            const timeAgo = formatTimeAgo(msg.createdAt);
            return (
                <div
                  key={index}
                  className={`items-start rounded-lg border-1 shadow-md px-4 pt-4 pb-1 mb-2
                  ${isCurrentUser ? 'bg-[#fffbeb]' : 'bg-white'}`} // Ganti background jika current user
                >
                <div className="flex items-center mb-4">
                <img
                  src={avatar}
                  alt="Avatar"
                  className="w-8 h-8 rounded-lg mr-2"
                  onClick={(e) => { e.stopPropagation(); handleProfileClick(msg.walletAddress); }}
                />
                <p className="text-sm font-semibold" onClick={(e) => { e.stopPropagation(); handleProfileClick(msg.walletAddress); }}>
                  {username}
                </p>
                </div>
                <div>
                <p className="mt-4 text-sm font-lato whitespace-pre-wrap mb-4">{msg.message}</p>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <button onClick={() => handleReply(username)} className="mr-4 flex text-xs items-center"><ReplySVG className="h-5 w-5 mr-1"/>reply</button>
                    <button onClick={() => handleLike(msg.id)} className=" flex items-center text-xs text-[gray] mr-4"><LikeSVG className="h-4 w-4"/> <span className="ml-1 mr-1">{msg.likes || 0}</span> likes</button>
                    {msg.walletAddress === account && (
                      <button onClick={() => handleDeleteMessage(msg.id)}>
                        <DeleteSVG className="h-4 w-4"/>
                      </button>
                    )}
                  </div>
                  <p className="text-xs text-right text-gray-500 justify-end items-center">{timeAgo}</p>
                  {(msg.replies || []).map((reply, replyIndex) => (
                    <div key={replyIndex} className="ml-4 mt-2 border-l-2 pl-2 border-gray-300">
                      <p className="font-semibold">{reply.username}: {reply.message}</p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSendMessage} className="flex border rounded-xl px-2 py-2">
          <img
            src={userProfile.avatar || "https://cdn-icons-png.flaticon.com/512/147/147144.png"}
            alt="Avatar"
            className="w-10 h-10 rounded-lg mr-2"
          />
          <textarea
            ref={textareaRef}
            className="w-full p-2 font-lato focus:outline-none text-sm border rounded-lg overflow-hidden"
            style={{ whiteSpace: 'pre-wrap' }}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message here..."
            rows={1}
            required
          />
          <button type="submit" className="ml-2 bg-[#38bdf8] mr-2 px-4 px-1 rounded-full">
            <SendSVG className="h-6 w-6"/>
          </button>
        </form>
      </div>
      <div className="hidden lg:block lg:w-2/5 p-4"> {/* Ubah menjadi 40% */}
        <Sidebar />
      </div>
    </div>
  );
};

export default ChatGroup;

import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Styles from "./BaseLayout.module.css";
import { SidebarContext } from "../../context/SidebarContext/GlobalProvider";
import { useActiveItem } from "../../hooks/setActiveItem";
import { ThemeContext } from "../../context/ThemeContext/ThemeProvider";
import Topbar from "../Topbar/Topbar";
import { ToastContainer } from "react-toastify";

export default function BaseLayout({ children, noTopbar, noSidebar }) {
  const { showSidebar, setShowSidebar } = useContext(SidebarContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const [sideBarMobile, setSideBarMobile] = useState(false);
  const [tempfixed, setTempFixed] = useState(true);
  const [activeItem] = useActiveItem();

  const handleTempFixed = () => {
    setTheme(!tempfixed ? "light" : "dark");
    setTempFixed(!tempfixed);
  };

  const hideSidebar = () => {
    if (sideBarMobile) {
      setSideBarMobile(false);
    }
  };

  useEffect(() => {
    if (theme === "dark") {
      setTempFixed(false);
    }
  }, [theme]);

  return (
    <div className="bg-[#f1f5f9] m-0 p-0">
      <div className={`flex w-full ${noTopbar ? "" : ""}`}>
        {noSidebar ? null : (
          <div className="relative">
            <div
              className={`${
                sideBarMobile ? "block" : "hidden"
              } md:hidden w-[270px] h-screen bg-white absolute dark:bg-dark-1`}
            >
              <Sidebar
                fullSidebar={true}
                tempfixed={tempfixed}
                handleTempFixed={handleTempFixed}
                activeLink={activeItem}
              />
            </div>
          </div>
        )}
        {noSidebar ? null : (
          <div
            className={`hidden md:flex w-[270px] bg-white dark:bg-dark-1 ${Styles.sideBar} ease-in-out duration-300 ${
              showSidebar ? "translate-x-0" : "-translate-x-[130px]"
            }`}
          >
            <Sidebar
              fullSidebar={showSidebar}
              tempfixed={tempfixed}
              handleTempFixed={handleTempFixed}
              activeLink={activeItem}
            />
          </div>
        )}

        <div
          className={`w-full dark:bg-dark md:w-[calc(100%-270px)] md:ml-[270px] ease-in-out duration-300 ${
            showSidebar ? "translate-x-0" : "-translate-x-[10px]"
          }`}
        >

          <div
            className="pb-10 mt-[60px] md:mt-14 font-gilroy bg-tree-pattern-mobile md:bg-tree-pattern bg-center bg-no-repeat bg-contain z"
            onClick={hideSidebar}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

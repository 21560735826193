import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useEthers } from '@usedapp/core';
import WalletSVG from '../../svgs/New/Wallet';
import DashboardSVG from 'svgs/Sidebar/dashboard';
import MarketSVG from 'svgs/New/Market';
import StakingSVG from 'svgs/Sidebar/staking';
import TradeSVG from 'svgs/Sidebar/trade';
import AirdropSVG from 'svgs/Sidebar/Airdrop';
import VoteSVG from 'svgs/New/Vote';
import PremiumSVG from 'svgs/New/Premium';
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import ConnectedButton from './Button/ConnectedButton';
import DisconnectedButton from './Button/DisconnectedButton';
import BurgerIcon from 'svgs/New/Menu';
import NewsSVG from 'svgs/New/News';
import VerifySVG from 'svgs/New/Verify';
import Dropdown from './Dropdown';
import Setting from './Setting';
// import SearchUser from './Search';

export default function Topbar() {
  const { account } = useEthers();
  const [username, setUsername] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // State untuk burger menu
  const [accountMenuOpen, setAccountMenuOpen] = useState(false); // State untuk Account submenu
  const [communityMenuOpen, setCommunityMenuOpen] = useState(false); // State untuk Community submenu
  const [isVisible, setIsVisible] = useState(true); // State untuk visibility menu
  const [lastScrollY, setLastScrollY] = useState(0); // State untuk posisi scroll sebelumnya

  const fetchUsername = async (walletAddress) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/profile/${walletAddress}`);
      if (response.data && response.data.username) {
        setUsername(response.data.username);
      }
    } catch (error) {
      console.error('Failed to fetch username:', error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleAccountMenu = () => {
    setAccountMenuOpen(!accountMenuOpen);
  };

  const toggleCommunityMenu = () => {
    setCommunityMenuOpen(!communityMenuOpen);
  };

  // Fungsi untuk menangani scroll
  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      // Jika discroll ke bawah
      setIsVisible(false);
    } else {
      // Jika discroll ke atas
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);



  return (
    <div>
      {/* Menu atas yang hanya muncul di perangkat seluler */}
      <nav className={`p-2 fixed top-0 w-full flex justify-between z-10 bg-white md:border-b-2 transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <a href="/about">
        <img
          src="https://i.ibb.co.com/3CP0xNL/logo512.png"
          alt="Logo"
          className="w-10 h-10"
        />
      </a>
        <div className="flex ">
          {/* {account && (
            <div className="flex items-center">
              <div className="w-10 h-10 flex justify-center border-2 rounded-md border-primary-green items-center">
                <WalletSVG className="fill-dark-text" />
              </div>
            </div>
          )}
          <SearchUser/> */}
          {account && (
          <div>
            <Setting />
          </div>
          )}
          {account ? (
            <div className="group">
              <div className="h-10 lg:h-12">
                <ConnectedButton />
              </div>
            </div>
          ) : (
            <DisconnectedButton />
          )}
        </div>
      </nav>

      {/* Menu bawah yang berbaris seperti di Twitter */}
      <div className={`md:hidden fixed bottom-0 w-full rounded-xl m-1 flex justify-around bg-gray-100 text-white py-2 z-10 transition-transform duration-300 ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}>
        <a href="/" className="flex flex-col items-center hover:bg-gray-100 px-4 py-2">
          <DashboardSVG className="mb-1 w-6 h-6" />
        </a>
        <a href="/announcements" className="flex flex-col items-center hover:bg-gray-100 px-4 py-2">
          <NewsSVG className="mb-1 w-6 h-6" />
        </a>
        <div className="flex flex-col items-center hover:bg-gray-100 px-4 py-2 cursor-pointer" onClick={toggleCommunityMenu}>
          <StakingSVG className="mb-1" />
        </div>
        {communityMenuOpen && (
          <div className="absolute bottom-12 bg-gray-100 w-full flex flex-col items-start p-2">
            <a href="/community" className="flex items-center hover:bg-gray-100 text-black py-2 w-full">
              <StakingSVG className="mr-2" />
              General Chat
            </a>
            <a href="/vote" className="flex items-center hover:bg-gray-100 text-black py-2 w-full">
              <VoteSVG className="mr-2" />
              Vote
            </a>
          </div>
        )}
        <a href="/exchange" className="flex flex-col items-center hover:bg-gray-100 px-4 py-2">
          <MarketSVG className="mb-1 w-6 h-6" />
        </a>
      {/*   <div className="flex flex-col items-center hover:bg-gray-100 px-4 py-2 cursor-pointer" onClick={toggleAccountMenu}>
          <TradeSVG className="mb-1 w-6 h-6" />
        </div>
        {accountMenuOpen && (
          <div className="absolute bottom-12 bg-gray-100 w-full flex flex-col items-start p-2">
            <a href={`/account/${account}`} className="flex items-center hover:bg-gray-100 text-black py-2 w-full">
              <TradeSVG className="mr-2" />
              Profile
            </a>
            <a href={`/portfolio/${account}`} className="flex items-center hover:bg-gray-100 text-black py-2 w-full">
              <TradeSVG className="mr-2" />
              Wallet
            </a>
            <a href="/Subscription" className="flex items-center hover:bg-gray-100 text-black py-2 w-full">
              <PremiumSVG className="mr-2" />
              Premium
            </a>
            <a href={`/register/${account}`} className="flex items-center hover:bg-gray-100 text-black py-2 w-full">
              <VerifySVG className="mr-2" />
              Verify
            </a>
          </div>
        )}
        <a href="/about" className="flex flex-col items-center hover:bg-gray-100 px-4 py-2">
          <AirdropSVG className="mb-1 w-6 h-6" />
        </a>  */}
      </div>
    </div>
  );
}

import React, { useState } from 'react';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);

    // Send form data to your backend API
    try {
      const response = await fetch('YOUR_BACKEND_API_URL', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'info@bullpad.org',
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });

      if (response.ok) {
        console.log('Email sent successfully');
        // Reset form after submission
        setFormData({ name: '', email: '', message: '' });
      } else {
        console.error('Error sending email:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className="footer-bg rounded-3xl border-gray-200 p-8 mt-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex items-center justify-center md:justify-start pt-2">
          <img
            src="https://www.wipster.io/hubfs/Images/Headers/contactus-chat.png"
            alt="Mission Image"
            className="rounded-lg md:w-100"
          />
        </div>
        <div className="flex flex-col">
          <h1 className="font-bold font-lato text-md text-left mb-4">Contact Us</h1>
          <p className="text-left text-2xl font-bold font-lato mb-6">We'd love to hear from you! Please fill out the form below.</p>

          <form onSubmit={handleSubmit} className="space-y-4 font-lato">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:border-[#fb7185] focus:ring focus:ring-[#fb7185]"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:border-[#fb7185] focus:ring focus:ring-[#fb7185]"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:border-[#fb7185] focus:ring focus:ring-[#fb7185]"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-[#fb7185] justify-left text-white py-2 rounded-md hover:bg-[#f05a7f] transition duration-200"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23 12a11 11 0 0 1-18.037 8.451l-.256.256A1 1 0 0 1 4 21a1 1 0 0 1-.383-.076A1 1 0 0 1 3 20v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 .707 1.707l-.322.322A9 9 0 1 0 3 12a9 9 0 0 0 .18 1.8 1 1 0 0 1-1.96.4A11 11 0 1 1 23 12M12 5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2h-4V6a1 1 0 0 0-1-1" />
  </svg>
);
export default SVGComponent;

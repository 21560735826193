import React, { useState, useEffect, useRef } from 'react';
import DisconnectSVG from 'svgs/Topbar/Disconnect';
import { useEthers } from '@usedapp/core';
import WalletIcon from "components/Profile/Wallet/Svgs/Wallet";
import TradeSVG from 'svgs/Sidebar/trade';
import VerifySVG from 'svgs/New/Verify';
import PremiumSVG from 'svgs/New/Premium';
import SettingIcon from './SettingIcon';

export default function Dropdown() {
  const { deactivate, account } = useEthers();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const onDisconnect = async () => {
    try {
      deactivate();
    } catch (error) {
      console.error(error);
    }
  };

  // Handle closing dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <SettingIcon className="ml-2 md:w-8 md:h-8 mt-2 h-6 w-6 rounded-lg object-cover" />
      </button>

      {isOpen && (
        <div className="absolute left-0 top-full mt-2 rounded-md border border-[#e7e3ff] dark:border-dim-text-dark bg-white dark:bg-dark-1 z-10 w-48 shadow-lg">
          <div className="flex flex-col">
            <div className="flex items-center justify-between cursor-pointer px-2 py-2">
              <a href={`/account/${account}`} className="flex items-center">
                <TradeSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />
                <span className="ml-2">Profile</span>
              </a>
            </div>
            <div className="flex items-center justify-between cursor-pointer px-2 py-2">
              <a href={`/portfolio/${account}`} className="flex items-center">
                <WalletIcon className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />
                <span className="ml-2">Wallet</span>
              </a>
            </div>
            <div className="flex items-center justify-between cursor-pointer px-2 py-2">
              <a href="/Subscription" className="flex items-center">
                <PremiumSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />
                <span className="ml-2">Premium</span>
              </a>
            </div>
            <div className="flex items-center justify-between cursor-pointer px-2 py-2">
              <a href={`/register/${account}`} className="flex items-center">
                <VerifySVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />
                <span className="ml-2">Verify</span>
              </a>
            </div>
          </div>

          <div
            onClick={() => onDisconnect()}
            className="flex items-center text-gray dark:text-gray-dark cursor-pointer justify-between border border-gray hover:bg-primary-green hover:text-light dark:border-gray-dark border-opacity-20 px-2 py-2">
            <span className="font-medium font-lato">Disconnect</span>
            <DisconnectSVG className="fill-dark-text dark:fill-light-text ml-2" />
          </div>
        </div>
      )}
    </div>
  );
}

import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 5v16h4v5.094l1.625-1.313L12.344 21H22V5Zm2 2h16v12h-8.344l-.281.219L8 21.906V19H4Zm20 2v2h4v12h-4v2.906L20.344 23h-7.5l-2.5 2h9.312L26 30.094V25h4V9Z" />
  </svg>
);
export default SVGComponent;

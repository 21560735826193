import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from "swiper/modules";

const featuresMap = [
  {
    key: 'Decentralized Data',
    features: 'Take full control of your personal data. Unlike traditional platforms, our SocialFi solution ensures that only you decide how your information is used and shared. No more middlemen or data breaches.',
    imageLink: 'https://png.pngtree.com/png-clipart/20230824/original/pngtree-decentralized-application-abstract-concept-vector-illustration-picture-image_8426028.png',
  },
  {
    key: 'Content Monetization',
    features: 'Earn tokens directly for your content. Whether you’re posting, sharing, or engaging with other users’ content, every interaction can be rewarded, allowing you to monetize your influence and creativity.',
    imageLink: 'https://cdn3d.iconscout.com/3d/premium/thumb/monetize-video-content-6461448-5379571.png?f=webp',
  },
  {
    key: 'Community Governance',
    features: 'Be part of the decision-making process. Our platform is community-driven, enabling users to participate in voting on platform upgrades, features, and policies through a decentralized governance model.',
    imageLink: 'https://cdn3d.iconscout.com/3d/premium/thumb/community-3d-icon-download-in-png-blend-fbx-gltf-file-formats--team-network-people-group-character-digital-marketing-pack-branding-icons-5487791.png?f=webp',
  },
  {
    key: 'Tokenized Rewards System',
    features: 'Every activity on our platform is incentivized. From creating content to engaging in discussions, users earn tokens that can be used within the platform or traded on decentralized exchanges.',
    imageLink: 'https://cdn3d.iconscout.com/3d/premium/thumb/reward-3d-icon-download-in-png-blend-fbx-gltf-file-formats--award-achievement-winner-medal-games-pack-sports-icons-5115806.png?f=webp',
  },
];

const KeyFeatures = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="bg-[#fff1b8] rounded-3xl shadow-xl shadow-[#fde586] border-gray-200 mr-4 p-8 mt-8 mb-8">
      <div className="items-center">
        <h1 className="text-lg font-bold text-left font-lato text-[#fb7185]">How It Works</h1>
        <h2 className="text-3xl font-bold text-left mb-4 font-lato text-black">Key Features of Our MeetFi Platform</h2>

        <Swiper
          slidesPerView={1} // Default untuk layar kecil
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          breakpoints={{
            640: {
              slidesPerView: 1, // 1 slide untuk layar kecil
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2, // 2 slide untuk tablet
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3, // 3 slide untuk desktop
              spaceBetween: 40,
            },
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {featuresMap.map((plan) => (
            <SwiperSlide key={plan.key}>
              <div className="shadow-lg bg-[#f1f5f9] rounded-xl p-2 flex flex-col gap-2 items-center justify-center">
                <img src={plan.imageLink} className="w-[80px]" alt={`${plan.key} image`} />
                <p className="text-xl text-center font-lato font-bold text-black">{plan.key}</p>
                <p className="text-base text-xs text-center text-black font-lato p-2">{plan.features}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default KeyFeatures;

// import { BSC } from '@usedapp/core'
import { Base } from './constants/testchain'

export const getNetworkConfig = () => ({
  readOnlyChainId: Base.chainId,
  readOnlyUrls: {
    // [BSC.chainId]: 'https://bsc-dataseed1.ninicoin.io/',
    // [ShardeumChain.chainId]: 'https://sphinx.shardeum.org',
    // [ZetaChain.chainId]: 'https://zetachain-mainnet.public.blastapi.io',
    [Base.chainId]: 'https://mainnet.base.org',// 'https://base.llamarpc.com',
  },
  networks: [Base],
  noMetamaskDeactivate: true,
  refresh: 'never',
  pollingInterval: 15000,
})

import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto bg-[white] mt-8 md:mt-0 p-8 border2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex">
          <div>
          <h1 className="text-lg font-bold font-lato text-[#fb7185]"> About Us </h1>
           <h1 className="text-2xl font-bold font-lato text-black"> Empowering Social Connections with Decentralized Finance </h1>
            <p className="text-black text-sm font-lato leading-relaxed mt-2">
              MeetFi is the fusion of social media and decentralized finance (DeFi),
              creating a new generation of platforms where users can monetize their content,
              connect with communities, and have full control over their data.
              Unlike traditional social media, which often prioritizes profit over privacy,
              SocialFi aims to empower users through blockchain technology.
            </p>
            <button className="bg-[#d946ef] font-lato text-sm text-white px-4 py-1 shadow-md shadow-[white] rounded-[12px] hover:bg-green-500 mt-4">
              Read More
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center md:justify-end pt-2 pb-4">
          <img
            src="https://img.freepik.com/free-vector/hr-manager-with-employee-interview-business-flow-chart-employee-assessment-software-hr-company-system-employee-check-programme-concept-illustration_335657-2088.jpg"
            alt="About Us Image"
            className="rounded-lg w-100%"
          />
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useEthers } from '@usedapp/core';
import Post from "./Post";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import CreateContent from "./Create/CreateContent";
import LoadIcon from '../../svgs/New/Loading';

const PostFeed = ({ category }) => {
  const { account } = useEthers();
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  const fetchPosts = async () => {
    let endpoint = `${BACKEND_URL}/api/posts/getPost`;
    let params = {};

    try {
      if (category === "Recents" || category === "trending") {
        params.sort = category === "Recents" ? "latest" : "trending";
      } else if (category === "Friends") {
        if (account) {
          const walletAddress = account;
          endpoint = `${BACKEND_URL}/api/posts/getFollowingPosts`;
          params = { walletAddress };
        } else {
          if (!account) {
            setPosts([]);
            return;
          }
          return;
        }
      }

      const response = await axios.get(endpoint, { params });
      console.log("Response data:", response.data); // Debug log
      const postsData = response.data;
      setPosts(postsData);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [category, account]);

  return (
    <div className="space-y-2">
    <div>
    {posts.length > 0 ? (
      posts.map((post) => (
        <Post key={post.id} post={post} />
      ))
    ) : (
      <p className="flex justify-center font-lato text-center py-4">
        <LoadIcon className="w-6 h-6 items-center mr-2" />
        No posts available
      </p>
    )}
      </div>
    </div>
  );
};

export default PostFeed;

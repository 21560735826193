import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M31 4h-3V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h3v5a1 1 0 0 0 1 1h9l3.293 3.293a.997.997 0 0 0 1.414 0L22 28h9a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1m-1 2v20h-8.828L18 29.172 14.828 26H6V6zM2 20V2h24v3H6a1 1 0 0 0-1 1v14zm8-8h16v1H10zm0 3h16v1H10zm0 3h16v1H10z"
      style={{
        fill: "#111918",
      }}
    />
  </svg>
);
export default SVGComponent;

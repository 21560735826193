import React from 'react';

export default function ActiveVotes({ proposals, startVoting }) {
  const activeProposals = proposals.filter(proposal => proposal.status === 'active');

  return (
    <section className="mb-8">
      <h2 className="text-xl font-semibold mb-4 font-lato">Active Votes</h2>
      {activeProposals.length > 0 ? (
        activeProposals.map(proposal => (
          <div key={proposal.id} className="bg-white shadow-md rounded-lg p-4 mb-4 font-lato">
            <h3 className="text-lg font-bold">{proposal.title}</h3>
            <p className="text-gray-700">{proposal.description}</p>
            <button
              className="mt-2 bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded"
              onClick={() => startVoting(proposal)}
            >
              Vote Now
            </button>
          </div>
        ))
      ) : (
        <p>No vote available</p>
      )}
    </section>
  );
}

import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Outline">
      <path d="M32.891 29.5a1 1 0 0 0-.862-.5 1 1 0 0 0-.862.493L24.4 41l-2.441-18.133a1 1 0 1 0-1.982.266L20.765 29H17a1 1 0 0 0 0 2h4l.033-.007L21.3 33H18a1 1 0 0 0 0 2h3.572l1.228 9.133a1 1 0 0 0 1.853.374l7.372-12.529L39.342 44.5a1 1 0 0 0 1.855-.372L42.412 35H46a1 1 0 0 0 0-2h-3.322l.268-2.011c.019 0 .035.011.054.011h4a1 1 0 0 0 0-2h-3.79l.781-5.868a1 1 0 0 0-1.982-.264L39.6 40.979Z" />
      <path d="M32 2a30 30 0 1 0 30 30A30.034 30.034 0 0 0 32 2m0 58a28 28 0 1 1 28-28 28.03 28.03 0 0 1-28 28" />
      <path d="M49.655 16.793a3.172 3.172 0 1 0-3.172 3.172 3.1 3.1 0 0 0 1.263-.266 19.994 19.994 0 0 1-25.054 30.008 1 1 0 0 0-.933 1.77 21.987 21.987 0 0 0 27.47-33.125 3.14 3.14 0 0 0 .426-1.559m-4.344 0a1.172 1.172 0 1 1 1.172 1.172 1.17 1.17 0 0 1-1.172-1.172M16.793 44.034a3.2 3.2 0 0 0-.692.081A19.78 19.78 0 0 1 12 32a20.023 20.023 0 0 1 20-20 19.8 19.8 0 0 1 8.463 1.874 1 1 0 0 0 .848-1.812 21.99 21.99 0 0 0-26.922 33.1 3.158 3.158 0 1 0 2.4-1.126Zm0 4.345a1.173 1.173 0 1 1 1.172-1.172 1.17 1.17 0 0 1-1.172 1.172" />
    </g>
  </svg>
);
export default SVGComponent;

import * as React from "react";
const SVGComponent = (props) => (
  <svg
  width={25}
  height={25}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle
      style={{
        fill: "#7aa5da",
      }}
      cx={256}
      cy={256}
      r={256}
    />
    <path
      style={{
        fill: "#5786b5",
      }}
      d="M511.924 250.077 398.283 136.625l-185.108 225.63 133.284 133.284C443.197 458.988 512 365.535 512 256c0-1.981-.031-3.955-.076-5.923z"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M383.717 133.52c10.206-3.982 20.756 4.948 18.515 15.67l-45.77 218.781c-2.138 10.257-14.188 14.877-22.635 8.671l-69.094-50.717-35.219 35.961c-6.189 6.31-16.86 3.741-19.515-4.672l-25.41-80.662-68.112-20.118c-8.947-2.638-9.464-15.084-.793-18.48L383.717 133.52zm-33.599 48.545c2.982-2.638-.483-7.292-3.862-5.189l-147.015 91.177a6.681 6.681 0 0 0-2.862 7.671l20.049 88.04c.397 1.345 2.327 1.155 2.5-.241l4.482-67.094a6.634 6.634 0 0 1 2.207-4.275l124.501-110.089z"
    />
    <path
      style={{
        fill: "#9ec2e5",
      }}
      d="M346.257 176.876c3.379-2.103 6.844 2.551 3.862 5.189l-124.5 110.089a6.643 6.643 0 0 0-2.207 4.275l-4.482 67.094c-.172 1.396-2.103 1.586-2.5.241l-20.049-88.04a6.68 6.68 0 0 1 2.862-7.671l147.014-91.177z"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M217.154 364.678c-3.241-1.155-5.982-3.741-7.154-7.465l-25.41-80.662-68.112-20.118c-8.947-2.638-9.464-15.084-.793-18.48L383.717 133.52c6.585-2.569 13.326.241 16.653 5.448a14.418 14.418 0 0 0-2.189-2.603L216.343 284.81v6.499l-1-.724 1 40.926v32.823c.259.121.534.224.81.31v.034h.001z"
    />
    <path
      style={{
        fill: "#d1d1d1",
      }}
      d="M402.525 145.518a14.972 14.972 0 0 1-.293 3.672l-45.77 218.781c-2.138 10.257-14.188 14.877-22.635 8.671l-69.094-50.717-48.39-34.616v-6.499l181.838-148.446a14.377 14.377 0 0 1 2.189 2.603c.121.207.241.396.362.586.103.207.224.414.328.603.103.207.207.414.293.621.103.224.19.431.276.655.069.19.155.396.224.621.224.672.396 1.362.517 2.086.069.448.121.897.155 1.379z"
    />
    <path
      style={{
        fill: "#d1d1d1",
      }}
      d="m264.733 325.925-35.219 35.961c-.293.293-.603.586-.914.845-.31.259-.621.483-.948.707l-.017.017c-.655.431-1.327.793-2.034 1.086-.362.138-.707.276-1.069.362-1.5.448-3.034.569-4.551.414a12.614 12.614 0 0 1-1.172-.172c-.052 0-.103-.017-.155-.034a27.035 27.035 0 0 1-1.138-.328 1.535 1.535 0 0 1-.362-.138l-.81-33.133V291.31l48.389 34.615z"
    />
    <path
      style={{
        fill: "#dadde0",
      }}
      d="M228.6 362.73c-.31.259-.621.5-.948.724v-.017c.328-.224.638-.448.948-.707zm-.948.707v.017s0-.017-.017 0l.017-.017zm-2.051 1.104a12.146 12.146 0 0 0 2.034-1.086c-.655.43-1.345.792-2.034 1.086zm-5.62.775c1.517.155 3.051.034 4.551-.414a10.962 10.962 0 0 1-4.344.448.42.42 0 0 1-.207-.034z"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M221.205 365.402zm-1.224-.086a.443.443 0 0 0 .207.034 6.736 6.736 0 0 1-1.034-.138c.275.053.551.087.827.104z"
    />
    <path
      style={{
        fill: "#dadde0",
      }}
      d="M219.981 365.316a6.843 6.843 0 0 1-.827-.103 1.969 1.969 0 0 1-.345-.069c.379.069.775.138 1.172.172zm-2.465-.534c.379.121.759.224 1.138.328l-.465-.103c-.069-.017-.138-.034-.19-.069a2.635 2.635 0 0 1-.483-.156z"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="m218.188 365.006.465.103a2.219 2.219 0 0 1-.465-.103zm-.672-.224c.155.069.328.121.483.155a4.527 4.527 0 0 1-.776-.241c.103.034.189.069.293.086z"
    />
    <path
      style={{
        fill: "#dadde0",
      }}
      d="M217.516 364.782c-.103-.017-.19-.052-.293-.086-.017.017-.052 0-.069-.017v-.034c.12.068.241.102.362.137z"
    />
    <path
      style={{
        fill: "#d1d1d1",
      }}
      d="m216.343 331.511.81 33.133a6.573 6.573 0 0 1-.81-.31v-32.823z"
    />
    <path
      style={{
        fill: "#dadde0",
      }}
      d="M216.343 291.309v40.202l-1-40.926z"
    />
    <path
      style={{
        fill: "#adbcc9",
      }}
      d="m350.118 182.065-124.5 110.089a6.643 6.643 0 0 0-2.207 4.275l-4.474 67.1c-.172 1.396-2.103 1.586-2.517.241l-20.04-88.045a6.68 6.68 0 0 1 2.862-7.671l147.015-91.177c3.379-2.104 6.844 2.551 3.861 5.188z"
    />
  </svg>
);
export default SVGComponent;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LikeIcon from '../../svgs/New/Like';
import CommentIcon from '../../svgs/New/Comment';
import ShareIcon from '../../svgs/New/Share';
import TrashIcon from '../../svgs/New/Trash';
import DotsIcon from '../../svgs/New/3Dots';
import CoinIcon from '../../svgs/New/Coins';
import { useEthers } from '@usedapp/core';
import axios from "axios";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";

const Post = ({ post }) => {
  console.log('Post object:', post);
  const { account } = useEthers();
  const { walletAddress } = useParams();
  const [expanded, setExpanded] = useState(false);
  const [comments, setComments] = useState(post.comments || []);
  console.log('comment:', comments)
  const navigate = useNavigate();

  const handlePostClick = () => {
    navigate(`/post/${post.id}`);
  };

  const handleProfileClick = (walletAddress) => {
    if (walletAddress) {
      navigate(`/account/${walletAddress}`);
    }
  };

  const handleLike = async (e) => {
    e.stopPropagation();

    // Cek jika belum terhubung ke wallet
    if (!account) {
      alert("Connect wallet to start using the app");
      return;
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/api/posts/${post.id}/like`);
      console.log('Post liked:', response.data);
      post.likes = (post.likes || 0) + 1;
    } catch (error) {
      console.error("Failed to like post:", error.response ? error.response.data : error.message);
    }
  };


  const handleDeletePost = async (e) => {
    e.stopPropagation();
    try {
      const response = await axios.delete(`${BACKEND_URL}/api/posts/delete/${post.id}`);
      console.log('Post deleted:', response.data);
      // Refresh halaman
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete post:", error.response ? error.response.data : error.message);
    }
  };

  const handleShare = async (e) => {
      e.stopPropagation();
      try {
          await navigator.share({
              title: post.userInfo?.username || 'Post',
              text: post.description,
              url: `${window.location.origin}/post/${post.id}`, // URL post yang akan dibagikan
          });
          console.log('Post shared successfully!');
      } catch (error) {
          console.error('Error sharing post:', error);
          alert('Sharing failed. Please try again.');
      }
  };




  const formatTimeAgo = (date) => {
    const now = new Date();
    const postDate = new Date(date);
    const timeDiff = now - postDate;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 5) {
      const options = { month: 'short', day: '2-digit' };
      return postDate.toLocaleDateString(undefined, options);
    } else if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };


  const convertToClickableLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500">{part}</a>;
      }
      return part;
    });
  };

  const timeAgo = formatTimeAgo(post.createdAt);

  console.log('Post ID:', post.id);


  return (
    <div className="bg-[#f8fafc] md:rounded-xl rounded-2xl shadow-lg border-2 border-[#f3f4f6] p-4 mb-2 cursor-pointer relative" onClick={handlePostClick}>
      <div className="flex items-center mb-4">
        <img
          src={post.userInfo?.avatar || "/images/Avatar.png"}
          alt={post.userInfo?.username || "Anonymous"}
          className="w-10 h-10 rounded-lg mr-3 cursor-pointer"
          onClick={(e) => { e.stopPropagation(); handleProfileClick(post.walletAddress); }}
        />
        <div className="items-center">
          <span
            className="font-semibold mr-1 text-sm cursor-pointer font-lato"
            onClick={(e) => { e.stopPropagation(); handleProfileClick(post.walletAddress); }}
          >
            {post.userInfo?.username || "Anonymous"}
          </span>
          <div className="text-[10px] text-gray-500">{timeAgo}</div>
        </div>
      </div>
      {account && post.walletAddress.toLowerCase() === account.toLowerCase() && (
        <button
          onClick={handleDeletePost}
          className="absolute items-center top-4 right-4 text-red-500 hover:text-red-700 z-50"
        >
          <TrashIcon className="absolute items-center top-4 right-4 text-red-500 hover:text-red-700 z-50"/>
        </button>
      )}
      {/* <DotsIcon /> */}
      <pre className="mb-4 text-sm md:text-lg font-lato whitespace-pre-wrap">
        {convertToClickableLinks(post.description)}
      </pre>

      {/* Media (Video, Image, etc.) */}
      {post.contentType !== "text" && post.media && (
        post.contentType === "video" ? (
          <div className="mb-4 border-2 border-[#f1f5f9] media-container">
            <video controls className="w-full rounded-lg" src={post.media} />
          </div>
        ) : (
          <div className="mb-4 border-2 border-[#f1f5f9] media-container rounded-2xl">
            <img className="rounded-lg object-cover" src={post.media} alt="Post media" />
          </div>
        )
      )}

      {/* Post Interactions */}
      <div className="flex items-center justify-between text-gray-600">
        <div className="flex items-center">
          <span className="mr-4 flex text-sm items-center" onClick={handleLike}>
            <LikeIcon className="w-5 h-5 mr-1" /> {post.likes || 0} <span className="hidden"> Like </span>
          </span>
          <span className="mr-4 flex text-sm items-center">
            <CommentIcon className="w-5 h-5 mr-1" /> {comments.length || 0} <span className="hidden"> Comment </span>
          </span>
          <span className="flex text-sm items-center">
            <img src={"/images/logo512.png"} className="w-5 h-5 rounded-full mr-1" /> ${post.likes + 5 + (comments.length || 0)} <span className="hidden"> MXP </span>
          </span>
        </div>
        <button className="text-blue-500 flex items-center" onClick={handleShare}>
                <ShareIcon className="w-5 h-5 mr-1" />
            </button>
      </div>
    </div>
  );
};

export default Post;

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatUnits } from "@ethersproject/units";

const BalanceChart = ({ tokenBalances, tokenPrices }) => {
  const seriesData = Object.keys(tokenBalances).map(symbol => {
    const priceData = tokenPrices ? tokenPrices[symbol.toLowerCase()] : null;
    const price = priceData?.usd ?? 0;
    const balance = tokenBalances[symbol]
      ? parseFloat(formatUnits(tokenBalances[symbol], symbol === "ETH" ? 18 : (symbol === "MEFI" ? 18 : 6)))
      : 0;
    return balance * price; // Nilai dalam USD
  });

  const labels = Object.keys(tokenBalances);

  const chartOptions = {
    chart: {
      type: 'line',
    },
    labels: labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 mt-6">
      <h2 className="text-xl font-semibold text-center mb-4">Balance Overview</h2>
      <ReactApexChart options={chartOptions} series={seriesData} type="donut" />
    </div>
  );
};

export default BalanceChart;

import React from 'react';

const Mission = () => {
  return (
    <div className="container mx-auto bg-[white] mt-8 md:mt-0 p-8 border2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Gambar dipindahkan ke kiri */}
        <div className="hidden flex items-center justify-center md:justify-start pt-2 pb-4">
          <img
            src="https://static.vecteezy.com/system/resources/previews/020/716/993/original/3d-minimal-online-money-making-online-money-earn-concept-online-money-loan-coins-flowing-out-of-a-smartphone-into-a-wallet-3d-illustration-png.png"
            alt="Mission Image"
            className="rounded-lg w-100"
          />
        </div>
        {/* Teks dipindahkan ke kanan */}
        <div className="flex">
          <div>
            <h1 className="text-lg font-bold font-lato text-[#fb7185]"> Our Mission </h1>
            <h1 className="text-2xl font-bold font-lato text-black">
              A Revolutionary "Post to Earn" Platform
            </h1>
            <p className="text-black text-sm font-lato leading-relaxed mt-2">
            At MeetFi, our mission is to revolutionize the social media landscape
             by giving power back to the users. We believe in creating a
             decentralized social media ecosystem where every individual has the opportunity
             to not only express themselves freely but also earn for their contributions.
             Whether it’s posting, sharing, or engaging with content, we aim to ensure
             that your time and effort are rewarded.
            </p>
            <button className="bg-[#d946ef] font-lato text-sm text-white px-4 py-1 shadow-md shadow-[white] rounded-[12px] hover:bg-green-500 mt-4">
              Read More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;

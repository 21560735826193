import * as React from "react";
const SVGComponent = (props) => (
  <svg
  width={20}
  height={20}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#e7eced",
      }}
      d="M256 314.062c26.286 0 47.505 21.219 47.505 47.505S282.286 409.072 256 409.072s-47.505-21.219-47.505-47.505 21.219-47.505 47.505-47.505m-36.948-211.134h73.897v174.186h-73.897z"
    />
    <path
      style={{
        fill: "#ffd311",
      }}
      d="M256 55.423c110.74 0 200.577 89.838 200.577 200.577S366.74 456.577 256 456.577 55.423 366.74 55.423 256 145.26 55.423 256 55.423m36.948 221.69V102.928h-73.897v174.186h73.897zm10.557 84.454c0-26.286-21.219-47.505-47.505-47.505s-47.505 21.219-47.505 47.505 21.219 47.505 47.505 47.505 47.505-21.219 47.505-47.505"
    />
    <path
      style={{
        fill: "#7e8488",
      }}
      d="M256 7.918c137.026 0 248.082 111.056 248.082 248.082S393.026 504.082 256 504.082 7.918 393.026 7.918 256 118.974 7.918 256 7.918M456.577 256c0-110.74-89.838-200.577-200.577-200.577S55.423 145.26 55.423 256 145.26 456.577 256 456.577 456.577 366.74 456.577 256"
    />
    <path
      style={{
        fill: "#fabd1b",
      }}
      d="M55.423 256c0 110.74 89.838 200.577 200.577 200.577v-47.505c-26.286 0-47.505-21.219-47.505-47.505s21.219-47.505 47.505-47.505v-36.948h-36.948V102.928H256V55.423C145.26 55.423 55.423 145.26 55.423 256"
    />
    <path
      style={{
        fill: "#71777a",
      }}
      d="M55.423 256C55.423 145.26 145.26 55.423 256 55.423V7.918C118.974 7.918 7.918 118.974 7.918 256S118.974 504.082 256 504.082v-47.505C145.26 456.577 55.423 366.74 55.423 256"
    />
    <path d="M256 512c-68.386 0-132.676-26.627-181.024-74.976S0 324.386 0 256 26.627 123.324 74.976 74.976 187.614 0 256 0s132.676 26.627 181.025 74.976C485.373 123.324 512 187.614 512 256s-26.627 132.676-74.975 181.024S324.386 512 256 512m0-496.165c-64.157 0-124.47 24.98-169.828 70.337S15.835 191.843 15.835 256s24.98 124.47 70.337 169.828S191.843 496.165 256 496.165s124.469-24.98 169.828-70.337C471.186 380.47 496.165 320.157 496.165 256s-24.979-124.47-70.337-169.828C380.469 40.815 320.156 15.835 256 15.835" />
    <path d="M256 464.495c-114.965 0-208.495-93.53-208.495-208.495S141.035 47.505 256 47.505 464.495 141.035 464.495 256 370.965 464.495 256 464.495m0-401.155C149.767 63.34 63.34 149.767 63.34 256S149.767 448.66 256 448.66 448.66 362.233 448.66 256 362.233 63.34 256 63.34" />
    <path d="M300.866 285.031h-89.732V95.01h89.732zm-73.897-15.835h58.062V110.845h-58.062zM256 416.99c-30.561 0-55.423-24.862-55.423-55.423s24.862-55.423 55.423-55.423c30.56 0 55.423 24.862 55.423 55.423S286.56 416.99 256 416.99m0-95.011c-21.829 0-39.588 17.758-39.588 39.588S234.17 401.155 256 401.155s39.588-17.758 39.588-39.588-17.759-39.588-39.588-39.588" />
    <path
      style={{
        fill: "#fff",
      }}
      d="m355.772 389.006-9.523-12.651c38.248-28.791 60.184-72.659 60.184-120.355 0-44.6-19.631-86.637-53.859-115.335l10.173-12.134c37.826 31.713 59.521 78.174 59.521 127.469 0 26.595-6.099 52.01-18.128 75.535-11.454 22.401-28.179 42.275-48.368 57.471m-199.544 0c-20.189-15.197-36.914-35.07-48.368-57.471C95.832 308.01 89.732 282.595 89.732 256c0-49.295 21.694-95.756 59.521-127.469l10.173 12.134C125.198 169.362 105.567 211.4 105.567 256c0 47.696 21.937 91.564 60.185 120.355z"
    />
  </svg>
);
export default SVGComponent;

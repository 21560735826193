import React from 'react';
import Chat from '../../components/Community/ChatGroup';
import BaseLayout from "../../components/BaseLayout/BaseLayout";

const ChatPage = () => {
  return (
    <BaseLayout>
      <Chat />
    </BaseLayout>
  );
}

export default ChatPage;

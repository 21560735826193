import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 -3.71 75.17 75.17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      data-name="Path 1"
      d="M37.585 66.244s22.263-15.459 31.959-30.318c9.6-14.708.354-31.054-10.533-33.8-14.457-3.65-21.426 10.478-21.426 10.478S30.617-1.524 16.16 2.126C5.272 4.874-3.972 21.22 5.626 35.926c9.696 14.859 31.959 30.318 31.959 30.318Z"
      fill="none"
      stroke="black"
      strokeLinejoin="round"
      strokeWidth={3}
    />
  </svg>
);
export default SVGComponent;

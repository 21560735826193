import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";

const EditProfile = ({ walletAddress, userData, setUserData, setEditMode }) => {
  const [formData, setFormData] = useState({
    avatar: null,
    bannerUrl: null,
    bio: userData.bio || "",
    username: userData.username || "",
  });

  // Update state formData saat userData berubah
  useEffect(() => {
    setFormData({
      avatar: null,
      bannerUrl: null,
      bio: userData.bio || "",
      username: userData.username || "",
    });
  }, [userData]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageUpload = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, [field]: file });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('walletAddress', walletAddress);
    formDataToSend.append('username', formData.username);
    formDataToSend.append('bio', formData.bio);

    if (formData.avatar) {
      formDataToSend.append('avatar', formData.avatar);
    }

    if (formData.bannerUrl) {
      formDataToSend.append('banner', formData.bannerUrl);
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/api/profile/update`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        },
      });
      setUserData(response.data);
      console.log('response:', response)
      setEditMode(false);
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className="space-y-4 font-lato">
      <div>
        <label className="block">Avatar:</label>
        <div onClick={() => document.getElementById("avatarInput").click()} className="cursor-pointer">
          <img
            src={formData.avatar ? URL.createObjectURL(formData.avatar) : userData.avatar}
            alt="Avatar"
            className="w-24 h-24 rounded-lg border border-gray-300"
          />
        </div>
        <input
          type="file"
          id="avatarInput"
          style={{ display: "none" }}
          onChange={(e) => handleImageUpload(e, "avatar")}
        />
      </div>
      <div>
        <label className="block">Banner:</label>
        <div onClick={() => document.getElementById("bannerInput").click()} className="cursor-pointer">
          <img
            src={formData.bannerUrl ? URL.createObjectURL(formData.bannerUrl) : userData.bannerUrl}
            alt="Banner"
            className="w-full h-32 object-cover border border-gray-300"
          />
        </div>
        <input
          type="file"
          id="bannerInput"
          style={{ display: "none" }}
          onChange={(e) => handleImageUpload(e, "bannerUrl")}
        />
      </div>
      <div>
        <label className="block">Username:</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          className="border border-gray-300 rounded p-2 w-full"
        />
      </div>
      <div>
        <label className="block">Bio:</label>
        <textarea
          name="bio"
          value={formData.bio}
          onChange={handleInputChange}
          className="border border-gray-300 rounded p-2 w-full"
        />
      </div>
      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded-full"
      >
        Save Changes
      </button>
    </form>
  );
};

export default EditProfile;

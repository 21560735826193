import React, { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import axios from 'axios';
import { BACKEND_URL } from 'config/constants/LaunchpadAddress';

const ConnectedButton = () => {
  const { account, activateBrowserWallet } = useEthers();
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState('');

  const fetchUsername = async (walletAddress) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/profile/${walletAddress}`);
      if (response.data && response.data.username && response.data.avatar) {
        setUsername(response.data.username);
        setAvatar(response.data.avatar);
      } else {
        setAvatar('');
      }
    } catch (error) {
      console.error('Failed to fetch username:', error);
    }
  };

  useEffect(() => {
    // Jika wallet sudah terhubung, simpan ke local storage
    if (account) {
      localStorage.setItem('connectedAccount', account);
      fetchUsername(account);
    }
  }, [account]);

  const handleConnectWallet = () => {
    activateBrowserWallet();
  };

  return (
    <button
      onClick={handleConnectWallet}
      className="h-full flex border-2 rounded-xl border-primary-green items-center justify-between bg-white dark:bg-dark-1 ml-4"
    >
      <img
        className="ml-2 md:w-6 md:h-6 h-5 w-5 rounded-lg object-cover"
        src={avatar ? avatar : '/images/topbar/wallets/metamask.svg'}
        alt={avatar ? 'User' : 'Wallet Icon'}
      />
      <span className="font-lato font-bold ml-2 dark:text-light mr-2">
        {username ? username : account ? `${account.slice(0, 6)}...${account.slice(-4)}` : 'Connect Wallet'}
      </span>
    </button>
  );
};

export default ConnectedButton;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CommentForm from "./CommentForm";
import LikeIcon from '../../svgs/New/Like';
import CommentIcon from '../../svgs/New/Comment';
import GoBackIcon from '../../svgs/New/Back2';
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import CoinIcon from '../../svgs/New/Coins';
import Sidebar from "./Sidebar";
import BaseLayout from "../BaseLayout/BaseLayout";
import { useEthers } from '@usedapp/core';

const PostDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { account } = useEthers();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  console.log('comments:', comments)
  const [visibleComments, setVisibleComments] = useState(10);
  const [userData, setUserData] = useState({ username: 'Anonymous', avatar: 'https://via.placeholder.com/40' });
  const [walletsData, setWalletsData] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);

  useEffect(() => {
    if (account) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`${BACKEND_URL}/api/profile/${account}`);
          setUserData({
            username: response.data.username || 'Anonymous',
            avatar: response.data.avatar || 'https://via.placeholder.com/40'
          });
        } catch (error) {
          console.error("Failed to fetch profile data:", error);
        }
      };

      fetchUserData();
    }
  }, [account]);

  const fetchWallets = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/api/wallets`);
      setWallets(response.data); // Store wallet data in state
    } catch (error) {
      console.error("Failed to fetch wallet addresses:", error);
    }
  };

  useEffect(() => {
    fetchWallets();
  }, []);

  const fetchPost = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/posts/${id}`);
      const postData = response.data;
      console.log('Post data:', postData); // Add this line

      const commentsWithUserData = await Promise.all(postData.comments.map(async (comment) => {
        const userResponse = await axios.get(`${BACKEND_URL}/api/api/wallets`);
        const userData = userResponse.data || {};
        return {
          ...comment,
          username: userData.username || 'Anonymous',
          avatar: userData.avatar || 'https://via.placeholder.com/40',
        };
      }));

      setPost(postData);
      setComments(commentsWithUserData);
    } catch (error) {
      console.error("Failed to fetch post:", error);
    }
  };

  useEffect(() => {
    fetchPost();
  }, [id, walletsData]);

  const handleCommentSubmit = async (commentText) => {
    if (!commentText) return;

    try {
      const response = await axios.post(`${BACKEND_URL}/api/posts/${id}/comment`, {
        text: commentText,
        walletAddress: account,
      });
    } catch (error) {
      console.error("Failed to submit comment:", error);
    }
  };
// Pastikan ini dipanggil pada mount


  const handleProfileClick = (walletAddress) => {
    navigate(`/account/${walletAddress}`);
  };

  const loadMoreComments = () => {
    if (comments.length <= visibleComments) {
      setAllCommentsLoaded(true);
    } else {
      setVisibleComments(visibleComments + 10);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const commentDate = new Date(date);

    if (isNaN(commentDate.getTime())) {
      return "Invalid date";
    }

    const timeDiff = now - commentDate;
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Format tanggal dan waktu
    const options = { month: '2-digit', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = commentDate.toLocaleString('en-US', options);

    if (days > 5) {
      return formattedDate; // Kembalikan format tanggal jika lebih dari 5 hari
    } else if (days > 0) {
      return `${days} days ago`; // Kembalikan selisih hari
    } else if (hours > 0) {
      return `${hours} hours ago`; // Kembalikan selisih jam
    } else if (minutes > 0) {
      return `${minutes} minutes ago`; // Kembalikan selisih menit
    } else {
      return `${seconds}s ago`; // Kembalikan selisih detik
    }
  };

  if (!post) {
    return <p>Loading...</p>;
  }

  const timeFormatted = new Date(post.createdAt).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  // Anda dapat menggunakan timeFormatted di tempat yang diinginkan


  const sortedComments = [...comments].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row min-h-screen bg-white mb-10">
        <div className="mb-6 xl:w-3/5 p-2 md:p-4 mt-2">
          <button onClick={goBack} className="flex items-center font-lato text-lg text-black font-bold mb-6">
            <GoBackIcon /> <span className="ml-2"> Post Detail </span>
          </button>

          <div className="border-b-2">
            <div className="flex items-center mb-4">
              <img
                src={post.userInfo?.avatar || "/images/Avatar.png"}
                alt={post.userInfo?.username || "Anonymous"}
                className="w-10 h-10 rounded-lg mr-3"
                onClick={(e) => { e.stopPropagation(); handleProfileClick(post.walletAddress); }}
              />
              <span
                className="font-semibold font-lato"
                onClick={(e) => { e.stopPropagation(); handleProfileClick(post.walletAddress); }}
              >
                {post.userInfo?.username || "Anonymous"}
              </span>
            </div>
            <pre className="mb-4 font-lato whitespace-pre-wrap">
              {post.description}
            </pre>
            {post.contentType !== "text" && post.media && (
              post.contentType === "video" ? (
                <div className="mb-4 border-2 border-[#f1f5f9] media-container">
                  <video controls className="w-full rounded-lg" src={post.media} />
                </div>
              ) : (
                <div className="mb-4 border-2 border-[#f1f5f9] media-container rounded-2xl">
                  <img className="rounded-lg object-cover" src={post.media} alt="Post media" />
                </div>
              )
            )}
            <div className="flex items-center mb-4">
              <span className="text-xs font-lato text-gray-500">{timeFormatted}</span>
            </div>
            <div className="flex items-center text-gray-600 mb-6 borderlike">
              <span className="mr-4 flex text-sm items-center"><LikeIcon className="mr-1" /> {post.likes || 0}</span>
              <span className="mr-4 flex text-sm items-center"><CommentIcon className="mr-1" /> {comments.length || 0}</span>
              <span className="flex text-sm items-center"><CoinIcon className="mr-1" /> ${post.likes + 5 + (comments.length || 0)}</span>
            </div>
          </div>

          <div className="mt-4">
            <CommentForm onSubmit={handleCommentSubmit} />
            <div className="space-y-2">
              {sortedComments.slice(0, visibleComments).map((comment, index) => {
                const walletData = wallets.find(wallet => wallet.walletAddress.toLowerCase() === comment.walletAddress.toLowerCase()) || {};

                // Log walletData for debugging
                console.log("Wallet Data:", walletData);

                const avatar = walletData.avatar || "/default-avatar.png"; // Use default avatar if not found
                const username = walletData.username || comment.walletAddress; // Use walletAddress as username if not found

                // Log avatar and username for debugging
                console.log("Avatar:", avatar);
                console.log("Username:", username);

                return (
                  <div key={index} className="flex items-start space-x-2 bg-gray-100 rounded-lg p-2">
                    <img
                      src={avatar}
                      alt={username}
                      className="w-10 h-10 rounded-lg"
                      onClick={(e) => { e.stopPropagation(); handleProfileClick(comment.walletAddress); }}
                    />
                    <div className="flex flex-col">
                      <span
                        className="flex items-center font-semibold font-lato cursor-pointer"
                        onClick={(e) => { e.stopPropagation(); handleProfileClick(comment.walletAddress); }}
                      >
                        {username}
                      </span>
                      <span className="text-xs text-gray-500 mb-2">{formatTimeAgo(comment.createdAt)}</span>
                      <span className="text-sm font-lato pb-2">{comment.text}</span>
                    </div>
                  </div>
                );
              })}

              {comments.length > visibleComments && !allCommentsLoaded && (
                <button
                  onClick={loadMoreComments}
                  className="text-blue-500 hover:underline mt-4 justify-center"
                >
                  Load More
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="hidden lg:block lg:w-2/5 p-4">
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );

};

export default PostDetail;

import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M829.439 911.36c45.241 0 81.92-36.682 81.92-81.92V194.56c0-45.245-36.675-81.92-81.92-81.92h-634.88c-45.245 0-81.92 36.675-81.92 81.92v634.88c0 45.238 36.679 81.92 81.92 81.92zm0 40.96h-634.88c-67.863 0-122.88-55.021-122.88-122.88V194.56c0-67.866 55.014-122.88 122.88-122.88h634.88c67.866 0 122.88 55.014 122.88 122.88v634.88c0 67.859-55.017 122.88-122.88 122.88" />
    <path d="M97.279 286.72h829.44c11.311 0 20.48-9.169 20.48-20.48s-9.169-20.48-20.48-20.48H97.279c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48m351.083 148.914c-6.822-4.323-15.723.574-15.723 8.649v280.914c0 8.081 8.897 12.975 15.723 8.649l221.645-140.452c6.346-4.021 6.346-13.277-.001-17.298L448.361 435.634zm21.924-34.599 221.645 140.462c31.727 20.104 31.727 66.39 0 86.495L470.286 768.444c-34.096 21.604-78.607-2.878-78.607-43.248V444.282c0-40.361 44.513-64.851 78.607-43.248z" />
  </svg>
);
export default SVGComponent;

import React, { useState, useRef } from 'react';

const Home  = () => {

  return (
    <div
      className="md:w-full md:h-screen flex md:flex-row flex-col font-lato justify-center items-center md:content-center"
      style={{
        backgroundImage: `url(${window.innerWidth >= 768
          ? "/images/home/header-banner.png"
          : "/images/home/header-mobile.png"})`,  // Change background based on screen width
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="md:w-1/2 w-full flex flex-col items-center md:items-start p-0 md:p-8">
        <h1 className="md:text-5xl text-4xl font-bold text-center md:text-left text-black font-lato md:mt-0 mt-60">
          Grow your financial with <span className="text-[#fb7185] font-lato font-bold">MEETFI</span>
        </h1>
        <p className="mt-4 text-black text-center md:text-left text-lg font-lato">
          Monetize your content, control your data, and engage in a decentralized social platform.
        </p>
        <div className="flex gap-4 mt-4">
          <button className="bg-[#d946ef] text-lg font-lato text-white px-6 py-2 shadow-md shadow-[white] rounded-[12px] hover:bg-green-500">
            Buy $MEET
          </button>
          <button href="/content" className="bg-[#d946ef] font-lato text-lg text-white px-6 py-2 shadow-md shadow-[white] rounded-[12px] hover:bg-green-500 ml-2">
            Find Posts
          </button>
        </div>
      </div>

      {/* Image for desktop */}
      <div className="md:w-1/2 w-full p-4 md:flex hidden justify-center">
        <img
          src="/images/home/header.webp"
          className="inline w-[80%]"
          alt="Hamster Image"
        />
      </div>
    </div>
  );
}

export default Home;

import * as React from "react";
const SVGComponent = (props) => (
  <svg
  width={25}
  height={25}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112.197 112.197"
    style={{
      enableBackground: "new 0 0 112.197 112.197",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle
      style={{
        fill: "#55acee",
      }}
      cx={56.099}
      cy={56.098}
      r={56.098}
    />
    <path
      style={{
        fill: "#f1f2f2",
      }}
      d="M90.461 40.316a26.753 26.753 0 0 1-7.702 2.109 13.445 13.445 0 0 0 5.897-7.417 26.843 26.843 0 0 1-8.515 3.253 13.396 13.396 0 0 0-9.79-4.233c-7.404 0-13.409 6.005-13.409 13.409 0 1.051.119 2.074.349 3.056-11.144-.559-21.025-5.897-27.639-14.012a13.351 13.351 0 0 0-1.816 6.742c0 4.651 2.369 8.757 5.965 11.161a13.314 13.314 0 0 1-6.073-1.679l-.001.17c0 6.497 4.624 11.916 10.757 13.147a13.362 13.362 0 0 1-3.532.471c-.866 0-1.705-.083-2.523-.239 1.706 5.326 6.657 9.203 12.526 9.312a26.904 26.904 0 0 1-16.655 5.74c-1.08 0-2.15-.063-3.197-.188a37.929 37.929 0 0 0 20.553 6.025c24.664 0 38.152-20.432 38.152-38.153 0-.581-.013-1.16-.039-1.734a27.192 27.192 0 0 0 6.692-6.94z"
    />
  </svg>
);
export default SVGComponent;

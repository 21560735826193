import { useContext, useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import { Link, useLocation } from 'react-router-dom';
import { useSitemap } from '../../data/sitemap'; // Import the custom hook
import axios from 'axios';
import TwitterSVG from '../../svgs/Socials/twitter';
import DiscordSVG from '../../svgs/Socials/discord';
import { ThemeContext } from '../../context/ThemeContext/ThemeProvider';
import { SidebarContext } from '../../context/SidebarContext/GlobalProvider';
import TelegramSVG from '../../svgs/Socials/telegram';
import GithubSVG from '../../svgs/Socials/github';
import SidebarArrowSVG from 'svgs/Sidebar/sidebar_arrow';
import WalletSVG from '../../svgs/New/Wallet';
import ConnectedButton from '../Topbar/Button/ConnectedButton';
import DisconnectedButton from '../Topbar/Button/DisconnectedButton';
import Dropdown from '../Topbar/Dropdown';
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import Online from './Online';

const socials = [
  {
    id: 1,
    icon: <TelegramSVG className="fill-dark-text dark:fill-light-text" />,
    link: 'https://t.me/MeetFinance',
  },
  {
    id: 2,
    icon: <TwitterSVG className="fill-dark-text dark:fill-light-text" />,
    link: 'https://twitter.com/Meet_Finance',
  },
  // {
  //   id: 4,
  //   icon: <DribbleSVG className="fill-dark-text dark:fill-light-text" />,
  // },
]

export default function Sidebar({ fullSidebar, tempfixed, handleTempFixed, activeLink }) {
  const { account } = useEthers();
  const { setShowSidebar } = useContext(SidebarContext);
  const [activeItem, setActiveItem] = useState(null);
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const nav_items = useSitemap();
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    setActiveItem(activeLink);
  }, [activeLink]);

  const handleActiveItem = (nav_item) => {
    if (nav_item === activeItem) {
      setActiveItem(null); // Close submenu
    } else {
      setActiveItem(nav_item); // Open submenu
    }
  };

  const handleSmallSidebar = (nav_item) => {
    if (nav_item === activeItem) {
      setActiveItem(null);
    } else {
      setActiveItem(nav_item);
    }
    setShowSidebar(true);
  };

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/total`); // Ganti dengan URL lengkap jika diperlukan
        setTotalUsers(response.data.totalUsers);
      } catch (err) {
        console.error('Error fetching total users:', err.message);
      }
    };

    fetchTotalUsers();
  }, []);

  if (!fullSidebar) {
    return (
      <div className="w-full flex justify-end border-b-2">
        <div className="w-[30%] flex flex-col items-center mb-[5%] justify-between">
          <div className="logo-div flex mt-5">
            <img src="/images/Logo512.png" className='w-10 h-10' alt="logo" />
          </div>
          <div className="nav-items">
            {nav_items.map((nav_item) =>
              nav_item.extendable ? (
                <div key={nav_item.id} className="mt-8" onClick={() => handleSmallSidebar(nav_item.name)}>
                  {nav_item.name === activeLink ? nav_item.activeIcon : nav_item.icon}
                </div>
              ) : (
                <Link key={nav_item.id} to={nav_item.link}>
                  <div className="mt-8">{nav_item.name === activeLink ? nav_item.activeIcon : nav_item.icon}</div>
                </Link>
              )
            )}
          </div>
          <div className='flex flex-col justify-end border-t-2 border-[#858585] border-opacity-10 pt-5'>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full pb-5 flex flex-col justify-between mb-5">
      <div className='h-full'>
        <div className="logo-div flex ml-7 mt-5 mb-4">
        <a href="/about">
          <img src="/images/Logo-Text2.png" alt="logo" className='w-40 h-12' />
          </a>
        </div>
        <div className="mt-4 px-4">
        <Online />
        </div>
        {/* <div className="mt-4 md:ml-4">
          {account && (
            <div className="flex md:hidden w-10 h-10 justify-center border-2 rounded-md border-primary-green border-opacity-50 items-center">
              <WalletSVG className="fill-dark-text" />
            </div>
          )}
          {account ? (
            <div className="group">
              <div className="h-10 lg:h-12">
                <ConnectedButton />
              </div>
              <div className="hidden group-hover:flex hover:flex">
                <Dropdown />
              </div>
            </div>
          ) : (
            <DisconnectedButton />
          )}
        </div> */}
        <div className="nav-items h-full overflow-x-scroll items-center">
          {nav_items.map((nav_item) => (
            <div key={nav_item.id} className="mt-6 w-full cursor-pointer">
              <div onClick={() => handleActiveItem(nav_item.name)} className="font-lato flex justify-between items-center">
                <Link to={nav_item.link} className="flex ml-[12%] items-center">
                  {nav_item.name === activeLink ? nav_item.activeIcon : nav_item.icon}
                  <span className={`font-gilroy ml-2 ${'font-lato text-sm font-bold'}`}>
                    {nav_item.name}
                  </span>
                </Link>
                {nav_item.extendable && (
                  <div className="mr-4">
                    <SidebarArrowSVG className="fill-gray dark:fill-light-text hover:fill-primary-green" />
                  </div>
                )}
              </div>
              {nav_item.extendable && activeItem === nav_item.name && (
                <div className="ml-[20%] mt-2">
                  {nav_item.sublinks.map((sublink, index) => (
                    <Link key={index} to={sublink.link} className="font-lato block text-sm font-bold flex items-center py-2 pb-2">
                     <span className="mr-2">{sublink.icon}</span>
                      {sublink.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
          {activeItem === null && <div className="bg-white dark:bg-dark-1 pt-[20%]"></div>}
        </div>
      </div>

      <div className="md:hidden h-20"></div>
      <div className=" flex flex-col items-center border-t-2 border-[#858585] border-opacity-10 pt-1">

        <div className="flex w-full ml-10 mt-2">
          {socials.map((social) => (
            <a key={social.id} href={social.link} className="ml-[14px] first:ml-0" target="_blank" rel='noreferrer'>
              <div
                className="twitter flex items-center justify-center bg-[#F5F1EB] dark:bg-dark-3 w-[34px] h-[34px] rounded-md "
              >
                {social.icon}
              </div>
            </a>
          ))}
        </div>
        <div className=" mt-5">
          <span className="font-lato text-[10px] text-left font-gilroy font-medium">
            @2024 Meet Finance. All rights reserved.
          </span>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useEthers } from '@usedapp/core';
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import SearchIcon from '../../svgs/New/Search'; // Assume you have an SVG file for search icon
import TrendingIcon from '../../svgs/New/Trending'; // Assume you have an SVG file for trending icon

const Sidebar = () => {
  const { walletAddress } = useParams();
  const { account } = useEthers();
  const [trendingTags, setTrendingTags] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [following, setFollowing] = useState({}); // State untuk menyimpan status follow/unfollow
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrendingTags = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/posts/trending-tags`);
        setTrendingTags(response.data); // Set state dengan data trending tags
      } catch (error) {
        console.error("Failed to fetch trending tags", error);
      }
    };

    const fetchWallets = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/api/wallets`);
        setWallets(shuffleArray(response.data)); // Shuffle wallet data
      } catch (error) {
        console.error("Failed to fetch wallet addresses:", error);
      }
    };

    fetchTrendingTags();
    fetchWallets();
  }, []);

  // Function to shuffle array (Fisher-Yates Shuffle Algorithm)
  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Filter users based on search query
  useEffect(() => {
    if (searchQuery.length > 0) {
      const filtered = wallets.filter(user =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [searchQuery, wallets]);

  const handleProfileClick = (walletAddress) => {
    if (walletAddress) {
      navigate(`/account/${walletAddress}`);
    }
  };

  const handleFollowUnfollow = async (userWalletAddress) => {
    if (!account) {
      alert("Silakan hubungkan wallet Anda terlebih dahulu.");
      return;
    }

    try {
      const endpoint = following[userWalletAddress] ? "unfollow" : "follow";
      const response = await axios.post(`${BACKEND_URL}/api/profile/${endpoint}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        followerAddress: account.toLowerCase(),
        followingAddress: userWalletAddress,
      });

      if (response.data.message.includes("Berhasil")) {
        setFollowing((prev) => ({
          ...prev,
          [userWalletAddress]: !prev[userWalletAddress],
        }));
      }
    } catch (error) {
      console.error("Gagal mengikuti atau berhenti mengikuti:", error);
    }
  };

  return (
    <div className="space-y-6 bg-white shadow-md p-4 border rounded-lg">
      {/* Search Bar */}
      <div className="relative ">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          placeholder="Search for users..."
        />
        <SearchIcon className="absolute top-2 right-3 h-6 w-6 text-gray-400" />
      </div>

      {/* Search Results */}
      {filteredUsers.length > 0 && (
        <div className="mt-4 space-y-2">
          {filteredUsers.map(user => (
            <div key={user.id} className="flex items-center space-x-3 p-2 border-b border-gray-200">
              <img
                src={user.avatar || "/default-avatar.png"}
                alt={`${user.username}'s avatar`}
                className="h-6 w-6 rounded-full"
              />
              <span
                className="text-gray-700 text-xs font-semibold cursor-pointer"
                onClick={(e) => { e.stopPropagation(); handleProfileClick(user.walletAddress); }}
              >
                {user.username}
              </span>
            </div>
          ))}
        </div>
      )}

      {/* Recommended Users */}
    <h2 className="flex font-lato text-xl font-bold items-center mt-6">
      Suggestions
    </h2>
    <div className="space-y-4">
      {wallets
        .filter(user => user.walletAddress !== account && !following[user.walletAddress]) // Saring wallet yang terhubung dan yang sudah di-follow
        .slice(0, 3) // Ambil 3 rekomendasi
        .map(user => (
          <div key={user.id} className="flex items-center justify-between space-x-3 px-2 cursor-pointer">
            <div className="flex items-center">
              <img
                src={user.avatar || "/default-avatar.png"}
                alt={`${user.username}'s avatar`}
                className="h-10 w-10 rounded-lg bg-gray-200 mr-2"
                onClick={(e) => { e.stopPropagation(); handleProfileClick(user.walletAddress); }}
              />
              <span className="text-gray-700 font-lato text-lg font-semibold">{user.username}</span>
            </div>
            <button
              onClick={() => handleFollowUnfollow(user.walletAddress)}
              className={`ml-2 px-3 py-1 text-sm rounded-full font-lato ${following[user.walletAddress] ? "bg-red-500 text-white" : "bg-black text-white"}`}
            >
              {following[user.walletAddress] ? "Unfollow" : "Follow"}
            </button>
          </div>
        ))}
    </div>


      {/* Trending Topics */}
      <h2 className="flex font-lato text-xl font-bold items-center mt-6">
        Trending
      </h2>
      <ul className="space-y-2 font-lato">
        {trendingTags.map(({ id: tag, count }) => (
          <li key={tag} className="text-blue-500 hover:underline cursor-pointer">
            {tag} <span className="mx-2 text-gray-500">•</span>
            <span className="text-gray-500 text-[12px]">{count} posts</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;

import React from 'react';

const featuresMap = [
  {
    key: "Connect Your Wallet",
    features:
      "Create your account in just a few simple steps. Connect your Web3 wallet, like MetaMask or Trust Wallet, to get started. Your data stays safe and encrypted on the blockchain.",
    imageLink: "https://static.vecteezy.com/system/resources/previews/009/596/516/original/3d-illustration-of-wallet-icon-png.png",
  },
  {
    key: "Personalize Your Profile",
    features:
      "After signing up, you can customize your profile. Upload a profile picture, write a short bio, and publish your first post to engage with the community.",
    imageLink: "https://cdn3d.iconscout.com/3d/premium/thumb/verificar-perfil-6161394-5073628.png",
  },
  {
    key: "Monetize Your Content",
    features:
      "Post, comment, like, and share to start earning. Every interaction on the platform can generate tokens. The more you contribute, the more you can earn..",
    imageLink: "https://cdn3d.iconscout.com/3d/premium/thumb/salary-acceptance-letter-8292765-6617688.png",
  },
  {
    key: "Earn Rewards",
    features:
      "Each time you create or engage with content, you’ll receive tokens based on your activity. These tokens can be used within the platform, staked to earn more, or traded on decentralized exchanges.",
    imageLink: "https://cdn3d.iconscout.com/3d/premium/thumb/reward-date-5805193-4855042.png?f=webp",
  },
  {
    key: "Participate in VOTE",
    features:
      "Get involved in voting for new features, policy changes, or other important decisions. The platform is governed by the community, and your vote has real influence.",
    imageLink: "https://static.vecteezy.com/system/resources/previews/017/217/738/original/election-box-with-ballot-3d-illustration-png.png",
  },
  {
    key: "Withdraw Your Rewards",
    features:
      "At any time, you can withdraw the tokens you’ve earned to your wallet or use them within our platform ecosystem.",
    imageLink: "https://static.vecteezy.com/system/resources/thumbnails/009/394/396/small_2x/3d-illustration-colorful-hand-and-withdraw-money-png.png",
  },
];

const About = () => {
  return (
    <div className="home-howbg  rounded-t-3xl border-gray-200 mr-4 py-8 px-4 mt-8">
        <div className="items-center">
        <h1 className="text-lg font-bold text-center font-lato text-[#fb7185]"> How It Work </h1>
          <h2 className="text-3xl font-bold text-center mb-4 font-lato text-black">Easy way to earn $$</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 pt-4 md:gap-2">
          {featuresMap.map((plan) => (
            <div key={plan.key} className="md:mb-4 mb-2 shadow-lg bg-[#f1f5f9]  rounded-xl p-2 flex flex-col items-center justify-center">
              <img src={plan.imageLink} className="w-[80px]" alt={`${plan.key} image`} />
              <p className="text-xl text-center font-lato font-bold mb-2 text-black">{plan.key}</p>
              <p className="text-base text-xs text-center text-black font-lato pb-2">{plan.features}</p>
            </div>
          ))}
          </div>
        </div>
    </div>
  );
};

export default About;

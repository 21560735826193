import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="-10 -5 1034 1034"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M258 175q-50 0-92 24.5T99.5 266 75 358v484q0 50 24.5 92t66.5 66.5 92 24.5h484q50 0 92-24.5t66.5-66.5 24.5-92V358q0-50-24.5-92T834 199.5 742 175zm-37 126q116 0 222.5 44.5t188.5 126T758 660t44 222H686q0-92-35.5-177.5t-101-151-150.5-101T221 417zm0 196q77 0 147.5 29.5t125 83.5T577 735t29 147H493q0-53-21-103.5T413.5 690t-88-59T221 610zm78 226h2q33 0 56 23.5t23 56.5-23 56-56 23-56.5-23-23.5-56 22.5-56 55.5-24" />
  </svg>
);
export default SVGComponent;

import React from 'react';

const launchpadData = [
  /* {
    id: 1,
    logo: "https://png.pngtree.com/png-clipart/20220612/original/pngtree-dollar-coin-icon-3d-png-image_7966148.png",
    tokenName: "Token MEET",
    tokenPrice: 0.05,
    description: "MeetFi token for blockchain-based social media.",
    status: "Live",
    minAllocation: "1000 USDT",
    maxAllocation: "10000 USDT",
    type: "private",
  },
  {
    id: 2,
    logo: "https://png.pngtree.com/png-clipart/20220612/original/pngtree-dollar-coin-icon-3d-png-image_7966148.png",
    tokenName: "Token BULL",
    tokenPrice: 0.1,
    description: "BullEx token for multichain-based DEX.",
    status: "Upcoming",
    minAllocation: "1000 USDT",
    maxAllocation: "10000 USDT",
    type: "public",
  },
  {
    id: 3,
    logo: "https://png.pngtree.com/png-clipart/20220612/original/pngtree-dollar-coin-icon-3d-png-image_7966148.png",
    tokenName: "Token CORE",
    tokenPrice: 0.02,
    description: "CORE token for staking on DeFi platform.",
    status: "Ended",
    minAllocation: "1000 USDT",
    maxAllocation: "10000 USDT",
    type: "public",
  },
  {
    id: 4,
    logo: "https://png.pngtree.com/png-clipart/20220612/original/pngtree-dollar-coin-icon-3d-png-image_7966148.png",
    tokenName: "Token LAUNCH",
    tokenPrice: 0.08,
    description: "Launch token for supporting new projects through a launchpad.",
    status: "Live",
    minAllocation: "1000 USDC",
    maxAllocation: "10000 USDC",
    type: "public",
  },
  {
    id: 5,
    logo: "https://png.pngtree.com/png-clipart/20220612/original/pngtree-dollar-coin-icon-3d-png-image_7966148.png",
    tokenName: "Token MEET",
    tokenPrice: 0.05,
    description: "MeetFi token for blockchain-based social media.",
    status: "Live",
    minAllocation: "1000 USDT",
    maxAllocation: "10000 USDT",
    type: "public",
  }, */
];

const Launchpads = ({ status }) => {
  const filteredLaunchpads = launchpadData.filter(
    (launchpad) => launchpad.status === status
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
      {filteredLaunchpads.length === 0 ? (
        <p className="text-gray-700 font-lato text-center">No {status} launchpads available.</p>
      ) : (
        filteredLaunchpads.map((launchpad) => (
          <div key={launchpad.id} className="bg-white font-lato shadow-md rounded-xl p-6 border border-gray-200">
            <div className="flex">
              <img src={launchpad.logo} alt="logo" className="w-12 h-12 rounded-lg bg-gray-500 mr-2" />
              <div>
                <h2 className="text-xl items-center font-lato font-bold text-gray-700">
                  {launchpad.tokenName}
                </h2>
                <p className="text-sm items-center font-lato font-bold text-gray-700">
                  {launchpad.type}
                </p>
              </div>
            </div>
            <p className="text-gray-600 mt-4 mb-4">{launchpad.description}</p>
            <div className="flex text-sm justify-between mb-2">
              <p className=" text-gray-600">Min Contribution:</p>
              <p className="text-gray-600">{launchpad.minAllocation}</p>
            </div>
            <div className="flex text-sm justify-between">
              <p className="text-gray-600">Max Contribution:</p>
              <p className="text-gray-600">{launchpad.maxAllocation}</p>
            </div>
            <div className="flex text-sm justify-between mt-2">
              <p className="text-gray-600">Token Price:</p>
              <p className="text-gray-700 font-bold">{launchpad.tokenPrice} ETH</p>
            </div>
            <button className="mt-4 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none">
              Buy {launchpad.tokenName}
            </button>
          </div>
        ))
      )}
    </div>
  );
};

export default Launchpads;

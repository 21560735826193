import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSitemap } from '../data/sitemap';

const useActiveItem = (pathname) => {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const nav_items = useSitemap();

  useEffect(() => {
    nav_items.map((nav_item) => { // Use 'nav_items' instead of 'sitemap'
      if (location.pathname.includes(nav_item.link)) setActiveItem(nav_item.name);
      else if (nav_item.sublinks.includes(location.pathname)) setActiveItem(nav_item.name);
      return [activeItem, setActiveItem];
    });
  }, [location.pathname, nav_items, activeItem]); // Add dependencies to the useEffect

  return [activeItem, setActiveItem];
}

export { useActiveItem };

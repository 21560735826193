import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEthers } from '@usedapp/core';
import axios from "axios";
import EditProfile from "./EditProfile";
import Modal from "./Modal";
import { BACKEND_URL } from "../../config/constants/LaunchpadAddress";
import Sidebar from "../Content/Sidebar";
import PostList from "./PostList";
import BaseLayout from "../BaseLayout/BaseLayout";
import WalletSVG from '../../svgs/New/Wallet';
import CalendarSVG from '../../svgs/New/Calendar';
import { Link } from 'react-router-dom';

const ProfilePage = () => {
  const { walletAddress } = useParams();
  const { account } = useEthers();
  const normalizedWalletAddress = walletAddress.toLowerCase();
  const [userData, setUserData] = useState({
    username: "Anonymous",
    avatar: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg",
    bannerUrl: "/images/profile-header.png",
    bio: "This is a bio",
    followers: 0,
    following: 0,
    posts: [],
    createAt: '',
  });
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [totalEarning, setTotalEarning] = useState(0);
  console.log('totalEarning:', totalEarning)

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/profile/${normalizedWalletAddress}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      setUserData({
        ...userData,
        ...response.data,
      });

      const followResponse = await axios.get(`${BACKEND_URL}/api/follow/status/${normalizedWalletAddress}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      setIsFollowing(followResponse.data.isFollowing);
    } catch (error) {
      console.error("Gagal mengambil profil:", error);
    }
  };


  const fetchUserEarning = async () => {
    try {
      console.log('Sending request to:', `${BACKEND_URL}/api/posts/statsByWallet`);
      const coinEarningResponse = await axios.get(`${BACKEND_URL}/api/posts/statsByWallet`, {
        params: { walletAddress: normalizedWalletAddress },
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      setTotalEarning(coinEarningResponse.data.totalScore);
      console.log('coinEarningResponse:', coinEarningResponse)
    } catch (error) {
      console.error("Gagal mengambil profil:", error);
    }
  };


  const handleFollowUnfollow = async () => {
    if (!account) {
      alert("Silakan hubungkan wallet Anda terlebih dahulu.");
      return;
    }

    try {
      const endpoint = isFollowing ? "unfollow" : "follow";
      const response = await axios.post(`${BACKEND_URL}/api/profile/${endpoint}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        followerAddress: account.toLowerCase(),
        followingAddress: normalizedWalletAddress,
      });

      if (response.data.message.includes("Berhasil")) {
        setIsFollowing(!isFollowing);
      }
    } catch (error) {
      console.error("Gagal mengikuti atau berhenti mengikuti:", error);
    }
  };

  useEffect(() => {
    if (account) {
      if (normalizedWalletAddress === account.toLowerCase()) {
        setIsOwnProfile(true);
      }
    }
    fetchUserProfile();
    fetchUserEarning();
  }, [account, normalizedWalletAddress]);

  useEffect(() => {
    if (userData.followerList && account && userData.followerList.includes(account.toLowerCase())) {
      setIsFollowing(true);
    }
  }, [userData, account]);

  const { username, avatar, bannerUrl, bio, followers, following, createAt } = userData;

  const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};


  return (
    <BaseLayout>
      <div className="flex flex-col lg:flex-row min-h-screen bg-[#f1f5f9] mb-10">
        <div className="w-full lg:w-3/5 p-4 border-2 border-gray rounded-xl md:mt-4 mt-1 md:ml-2 bg-white"> {/* Ubah menjadi 60% */}
          <div className="w-full h-48 relative">
            <img src={bannerUrl} alt="Banner" className="w-full h-40 md:h-full object-cover opacity-80 md:opacity-80 rounded-lg" />
            <div className="absolute -bottom-4 md:-bottom-12 md:left-[60px] left-[10px] md:transform md:-translate-x-1/2">
              <img src={avatar} alt={username} className="w-24 h-24 rounded-lg bg-white border border-white shadow-lg" />
            </div>
          </div>

          <div className="relative md:mt-6  flex items-center justify-between px-4">

          <h1 className="md:flex text-2xl font-lato font-semibold mt-8">
            {username}
          </h1>
          <div className="flex items-center justify-center mt-4">
          {/* account && (
            <WalletSVG className="w-10 h-10 mr-2 -translate-y-1/2" />
          ) */}
            {isOwnProfile ? (
              <button
                onClick={() => setEditMode(true)}
                className="px-4 py-2 text-black shadow-sm shadow-slate-400 border-[#94a3b8] border-1 text-sm rounded-full -translate-y-1/2"
              >
                Edit Profile
              </button>
            ) : (
              <button
                onClick={handleFollowUnfollow}
                className={`px-4 py-2 ${isFollowing ? "text-gray-700 bg-gray-200" : "text-white bg-blue-500"} shadow-sm shadow-slate-400 border-[#94a3b8] border-1 text-sm rounded-full -translate-y-1/2`}
              >
                {isFollowing ? "Following" : "Follow"}
              </button>
            )}
            </div>
          </div>

          <div className="md:text-left  mt-4 px-4">
            {!editMode ? (
              <>
                <p className="text-gray-600 font-lato mb-4">{bio}</p>
                {/* <p className="flex text-gray-600 items-center font-lato mb-6"> <CalendarSVG className="w-4 h-4 mr-1" />Joined {formatDate(createAt)}</p> */}
                <div className="flex items-center font-lato justify-left mt-6">
                  <div className="items-center mr-10 ">
                    <p className="text-gray-600 text-sm">Following</p>
                    <span className="font-semibold text-xl mr-1">{following}</span>
                  </div>
                  <div className="items-center mr-10">
                    <p className="text-gray-600 text-sm">Followers</p>
                    <span className="font-semibold text-xl mr-1">{followers}</span>
                  </div>
                  <div className="items-center ">
                    <p className="text-gray-600 text-sm">MEET Earning</p>
                    <span className="font-semibold text-xl mr-1">{totalEarning}</span>
                  </div>
                </div>
              </>
            ) : (
              <Modal isOpen={editMode} onClose={() => setEditMode(false)}>
                <EditProfile
                  walletAddress={normalizedWalletAddress}
                  userData={userData}
                  setUserData={setUserData}
                  setEditMode={setEditMode}
                />
              </Modal>
            )}
          </div>
          <PostList walletAddress={normalizedWalletAddress} />
        </div>
        <div className="hidden lg:block lg:w-2/5 p-4"> {/* Ubah menjadi 40% */}
          <Sidebar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default ProfilePage;
